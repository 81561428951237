import { Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AccountApi } from '../../../../api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from 'src/app/_shared/_services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-account-menu',
    templateUrl: './account-menu.component.html',
    styleUrls: ['./account-menu.component.scss'],
})
export class AccountMenuComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    isAuth$: Observable<boolean>;
    firstName$: Observable<string | null>;
    lastName$: Observable<string | null>;
    email$: Observable<string | null>;
    avatar$: Observable<string | null>;

    form!: FormGroup;

    loginInProgress = false;

    @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();

    @HostBinding('class.account-menu') classAccountMenu = true;

    constructor(
        private fb: FormBuilder,
        public account: AccountApi,
        public router: Router,
        private auth: AuthenticationService,
        private cart: CartService,
        private toastr: ToastrService,
        private translate: TranslateService) {
        this.isAuth$ = this.account.user$.pipe(map(x => x !== null));
        this.firstName$ = this.account.user$.pipe(map(x => x ? x.userName : null));
        this.lastName$ = this.account.user$.pipe(map(x => x ? x.userSurname : null));
        this.email$ = this.account.user$.pipe(map(x => x ? x.userMail : null));
        this.avatar$ = this.account.user$.pipe(map(x => x ? x.userAvatar : null));
    }

    ngOnInit(): void {
        this.createLoginForm();
    }

    createLoginForm() {
        this.form = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]],
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    login(): void {
        this.form.markAllAsTouched();

        if (this.loginInProgress || this.form.invalid) {
            return;
        }

        this.loginInProgress = true;

        this.account.signInNew(
            this.form.value.email,
            this.form.value.password,
        ).pipe(
            finalize(() => this.loginInProgress = false),
            takeUntil(this.destroy$),
        ).subscribe(
            {
                next: (data) => {
                    this.auth.setToken(data.token);
                    this.auth.loginEvent();
                    this.cart.load();
                    this.router.navigateByUrl('/account/dashboard').then();
                    this.closeMenu.emit();
                },
                error: (e) => {
                    if (e instanceof HttpErrorResponse) {
                        this.form.setErrors({
                            server: `ERROR_API_${e.error.message}`,
                        });
                    } else {
                        alert(e);
                    }
                },
                complete: () => {
                    this.toastr.success(this.translate.instant('TEXT_TOAST_LOGIN_WELCOME'));
                }

            })
    }

    logout(): void {
        this.account.signOut().subscribe(() => {
            this.auth.logOutEvent();
            this.closeMenu.emit();
            this.router.navigateByUrl('/account/login').then();
        });
    }
}