import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, Observable, throwError } from "rxjs";
import { Brand } from "src/app/interfaces/brand";
import { ShopCategory } from "src/app/interfaces/category";
import { Filter } from "src/app/interfaces/filter";
import { FilterableList, ProductsList } from "src/app/interfaces/list";
import { Product } from "src/app/interfaces/product";
import { _Order } from "src/app/_shared/_interfaces/order";
import { AuthenticationService } from "src/app/_shared/_services/auth.service";
import { environment } from "src/environments/environment";
import { AbstractFilterBuilder } from "src/fake-server/filters/abstract-filter-builder";
import { CategoryFilterBuilder } from "src/fake-server/filters/category-filter-builder";
import { CheckFilterBuilder } from "src/fake-server/filters/check-filter-builder";
import { ColorFilterBuilder } from "src/fake-server/filters/color-filter-builder";
import { RadioFilterBuilder } from "src/fake-server/filters/radio-filter-builder";
import { RangeFilterBuilder } from "src/fake-server/filters/range-filter-builder";
import { RatingFilterBuilder } from "src/fake-server/filters/rating-filter-builder";
import { VehicleFilterBuilder } from "src/fake-server/filters/vehicle-filter-builder";
import { AddProductReviewData, GetBrandsOptions, GetCategoriesOptions, GetCategoryBySlugOptions, GetProductReviewsOptions, GetProductsListOptions, GetSearchSuggestionsOptions, GetSearchSuggestionsResult, _CheckoutData } from "../base";

@Injectable({
    providedIn: 'root'
})

export class ImplementationShopApi {

    baseURL: string = environment.baseUrl + "/";
    constructor(private http: HttpClient, private auth: AuthenticationService) { }

    getProductsListNew(options?: GetProductsListOptions): Observable<ProductsList> {
        const filterValues = options?.filters || {};
        const filters: AbstractFilterBuilder[] = [
            //   new CategoryFilterBuilder('category', 'Categories'),
            //   new VehicleFilterBuilder('vehicle', 'Vehicle'),
            //   new RangeFilterBuilder('price', 'Price'),
            //    new CheckFilterBuilder('brand', 'Brand'),
            //    new RadioFilterBuilder('discount', 'With Discount'),
            //    new RatingFilterBuilder('rating', 'Rating'),
            //    new ColorFilterBuilder('color', 'Color'),
        ];

        let productfilter = {
            pageNumber: options.page,
            pageSize: options.limit,
            orderBy: options.sort,
            searchText: options.filters.category,
            minPrice: '',
            maxPrice: ''


        }

        if (options.filters?.price != undefined) {
            let data = options.filters?.price.split('-')
            productfilter.minPrice = data[0]
            productfilter.maxPrice = data[1]
        }

        return this.http.post<ProductsList>(this.baseURL + "product/filter", productfilter).pipe(
            // map((data) => {
            //     filters.forEach(filter => {

            //         filter.makeItems(data.items, filterValues[filter.slug])
            //     });
            //     filters.forEach(filter => filter.calc(filters));
            //     return {
            //         ...data,
            //         filters: filters.map(x => x.build())
            //     }
            // })
        )
    }

    getCategoryBySlug(slug: string, options?: GetCategoryBySlugOptions): Observable<ShopCategory> {

        return this.http.get<ShopCategory>(this.baseURL + "category/check/" + slug)
    }

    getCategoriesNew(options?: GetCategoriesOptions): Observable<ShopCategory[]> {
        return this.http.get<ShopCategory[]>(this.baseURL + "category")
    }

    getBrandsNew(options?: GetBrandsOptions): Observable<Brand[]> {
        let brandfilter = {
            pageNumber: 1,
            pageSize: options.limit,
        }
        return this.http.post<Brand[]>(this.baseURL + "brand", brandfilter)
    }

    getProductBySlug(publicId: string): Observable<Product> {
        return this.http.get<Product>(this.baseURL + "product/" + publicId)
    }

    getSearchSuggestions(query: string, options?: GetSearchSuggestionsOptions): Observable<GetSearchSuggestionsResult> {
        var pagination = {
            searchText: query,
            pagesize: options.limitCategories || options.limitProducts
        }



        return this.http.post<GetSearchSuggestionsResult>(this.baseURL + "product/search", pagination);
    }


    getRelatedProductsNew(modelId: number, limit: number): Observable<ProductsList> {
        var pagination = {
            modelId: modelId,
            pageSize: limit,
            pageNumber: 1
        }

        return this.http.post<ProductsList>(this.baseURL + "product/filter", pagination)
    }



    // getProductsList(options?: GetProductsListOptions): Observable<ProductsList> {
    //     return this.impShopApi.getProductsListNew(options);
    // }

    // getProductBySlug(slug: string): Observable<Product> {
    //     return getProductBySlug(slug);
    // }

    // getProductReviews(productId: number, options?: GetProductReviewsOptions): Observable<ReviewsList> {
    //     return getProductReviews(productId, options);
    // }

    // addProductReview(productId: number, data: AddProductReviewData): Observable<Review> {
    //     return addProductReview(productId, data);
    // }

    // getProductAnalogs(productId: number): Observable<Product[]> {
    //     return getProductAnalogs(productId);
    // }

    // getRelatedProducts(productId: number, limit: number): Observable<Product[]> {
    //     return getRelatedProducts(productId, limit);
    // }

    getFeaturedProductsNew(productId: number, limit: number, orderby?: string): Observable<Product[]> {
        var pagination = {
            pageSize: limit,
            orderBy: orderby
        }
        return this.http.post<Product[]>(this.baseURL + "product/productsforhome/" + productId, pagination)
    }

    getLatestProductsNew(limit: number): Observable<Product[]> {
        var pagination = {
            pageSize: limit,

        }
        return this.http.post<Product[]>(this.baseURL + "product/productsforhome/" + 6, pagination)
    }

    // getPopularProducts(categorySlug: string, limit: number): Observable<Product[]> {
    //     return getPopularProducts(categorySlug, limit);
    // }

    // getTopRatedProducts(categorySlug: string, limit: number): Observable<Product[]> {
    //     return getTopRatedProducts(categorySlug, limit);
    // }

    // getSpecialOffers(limit: number): Observable<Product[]> {
    //     return getSpecialOffers(limit);
    // }

    // getLatestProducts(limit: number): Observable<Product[]> {
    //     return getLatestProducts(limit);
    // }

    // getSearchSuggestions(query: string, options?: GetSearchSuggestionsOptions): Observable<GetSearchSuggestionsResult> {
    //     return getSearchSuggestions(query, options);
    // }

    // checkout(data: CheckoutData): Observable<Order> {
    //     return checkout(data);
    // }

    getFilter(): Observable<Filter[]> {

        return this.http.get<Filter[]>(this.baseURL + "product/getfilter")
    }


    checkOutNew(checkoutData:_CheckoutData):Observable<_Order>{

        const order = { userPublicId: checkoutData.userPublicId, shippingAddressId:checkoutData.shippingAddress.id,billingAddressId:checkoutData.billingAddress.id };

      return  this.http.post<_Order>(this.baseURL+"order/createorder",order)

    }

}
