import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HamburgerMenu } from "src/app/_shared/_interfaces/hamburger-menu";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class ImplementationHamburgerMenuApi {
    baseURL: string = environment.baseUrl + "/";

    constructor(
        private http: HttpClient) {
    }

    getHamburgerMenuBrandWithModel(): Observable<HamburgerMenu[]> {
        return this.http.get<HamburgerMenu[]>(this.baseURL + "model/navigationbrandwithmodel")
    }
}