import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize, interval, Subject, takeUntil, timer } from 'rxjs';
import { ApiService } from 'src/app/_shared/_services/api.service';

@Component({
  selector: 'app-page-forgot-password',
  templateUrl: './page-forgot-password.component.html',
  styleUrls: ['./page-forgot-password.component.scss']
})

export class PageForgotPasswordComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();

  forgotPasswordForm!: FormGroup;

  count: any;
  countDown: any;

  sendMailCodeProgress: boolean = false;
  sendCodeProgress: boolean = false;

  showMail: boolean = false;
  hasCode: boolean = false;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.createForgotPasswordForm();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.countDown = null;
  }

  createForgotPasswordForm() {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: [''],
      mailCode: ['', [Validators.maxLength(5)]],
    });
  }

  sendMail(): void {
    this.forgotPasswordForm.markAllAsTouched();

    if (this.sendMailCodeProgress || this.forgotPasswordForm.invalid) {
      return;
    }

    this.sendMailCodeProgress = true;

    this.api.post("authentication/TakeMailCode", { email: this.forgotPasswordForm.get('email').value }).pipe(
      finalize(() => this.sendMailCodeProgress = false),
    ).subscribe(
      {
        next: () => {
          this.toastr.success(this.translate.instant('SEND_MAIL_MESSAGE'));
          this.showMail = true;
          this.startmyTimer();
        },
        error: (e) => {
          if (e instanceof HttpErrorResponse) {
            this.forgotPasswordForm.setErrors({
              server: `ERROR_API_${e.error.message}`,
            });
          } else {
            alert(e);
          }
        },
        complete: () => {
          this.toastr.error(this.translate.instant('REQUIRED_FIELD_MESSAGE'));
        }
      })
  }

  sendMailCode(): void {
    this.forgotPasswordForm.markAllAsTouched();

    if (this.sendCodeProgress || this.forgotPasswordForm.invalid || this.forgotPasswordForm.get("mailCode").value == '') {
      this.toastr.error(this.translate.instant('REQUIRED_FIELD_MESSAGE'));
      return;
    }

    this.sendCodeProgress = true;

    this.api.post("authentication/forgotpassword", this.forgotPasswordForm.value).pipe(
      finalize(() => this.sendCodeProgress = false),
      takeUntil(this.destroy$),
    ).subscribe(
      {
        next: () => {
          this.toastr.success(this.translate.instant('RESET_PASSWORD_MESSAGE'));
        },
        error: (e) => {
          if (e instanceof HttpErrorResponse) {
            this.forgotPasswordForm.setErrors({
              server: `ERROR_API_${e.error.message}`,
            });
          } else {
            alert(e);
          }
        },
        complete: () => console.log('Şifre değiştirildi')
      })

  }

  startmyTimer() {
    this.hasCode = false;
    this.count = 180;
    this.countDown = timer(0, 180000)
      .subscribe(x => { });
    this.countDown = interval(1000)
      .subscribe(x => {
        this.count = this.count - 1;
        if (this.count === 0) {
          this.countDown.unsubscribe();
          this.hasCode = true;
          this.count = 0;
          this.toastr.error(this.translate.instant('NO_ENTIRE_CODE_INFO'));
        }
      });
  }

}