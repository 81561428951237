import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneralMenu } from 'src/app/_shared/_interfaces/general-menu';
import { GeneralMenuApi } from '../base';
import { ImplementationGeneralMenuApi } from '../implementation-api/implemantation-general-menu.api';

@Injectable()
export class FakeGeneralMenuApi extends GeneralMenuApi {

    constructor(
        private impGeneralMenuApi: ImplementationGeneralMenuApi) {
        super();
    }

    getGeneralMenuBrandWithModel(): Observable<GeneralMenu[]> {
        return this.impGeneralMenuApi.getGeneralMenuBrandWithModel();
    }

}