import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AccountApi } from 'src/app/api';
import { theme } from '../../../../../data/theme';

@Component({
    selector: 'app-newsletter',
    templateUrl: './newsletter.component.html',
    styleUrls: ['./newsletter.component.scss'],
})
export class NewsletterComponent {
    private destroy$: Subject<void> = new Subject<void>();
    @HostBinding('class.footer-newsletter') classFooterNewsletter = true;

    theme = theme;

    form!: FormGroup;

    saveInProgress = false;

    socialLinks = [
        { type: 'facebook', url: theme.social.facebook_url, icon: 'fab fa-facebook-f' },
        { type: 'twitter', url: theme.social.twitter_url, icon: 'fab fa-twitter' },
        { type: 'youtube', url: theme.social.youtube_url, icon: 'fab fa-youtube' },
        { type: 'instagram', url: theme.social.instagram_url, icon: 'fab fa-instagram' },
        { type: 'rss', url: theme.social.rss_url, icon: 'fas fa-rss' },
    ];

    constructor(
        private account: AccountApi,
        private toastr: ToastrService,
        private translate: TranslateService,
        private fb: FormBuilder
    ) { }

    ngOnInit(): void {
        this.createNewsletter();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    createNewsletter() {
        this.form = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    save(): void {
        this.form.markAllAsTouched();

        if (this.saveInProgress || this.form.invalid) {
            return;
        }

        this.saveInProgress = true;

        this.account.addNewsletterNew(this.form.value)
            .pipe(
                finalize(() => this.saveInProgress = false),
                takeUntil(this.destroy$),
            ).subscribe(
                {
                    next: () => {
                        this.toastr.success(this.translate.instant('TEXT_TOAST_NEWSLETTER_ADDED'));
                        this.form.reset();
                    },
                    error: (e) => {
                        if (e instanceof HttpErrorResponse) {
                            this.toastr.error(this.translate.instant('TEXT_TOAST_NEWSLETTER_EMAIL_EXIST'))
                            // this.form.setErrors({
                            //     server: `ERROR_API_${e.error.message}`,
                            // });
                        } else {
                            alert(e);
                        }
                    },
                    complete: () => console.log('Abone olundu')
                })
    }
}
