<app-block-space layout="after-header"></app-block-space>

<div class="block">
    <div class="container container--max--lg">
        <div class="row">

            <div class="col-md-6 d-flex m-auto">
                <div class="card flex-grow-1">
                    <div class="card-body card-body--padding--2">
                        <h3 class="card-title">{{ 'HEADER_FORGOT_PASSWORD'|translate }}</h3>
                        <form [formGroup]="forgotPasswordForm">
                            <div *ngIf="forgotPasswordForm.errors && forgotPasswordForm.errors['server']"
                                class="alert alert-sm alert-danger">
                                {{ ((forgotPasswordForm.errors && forgotPasswordForm.errors['server']) || '')|translate
                                }}
                            </div>

                            <div class="form-group" *ngIf="!showMail">
                                <label for="signin-email">{{ 'INPUT_EMAIL_ADDRESS_LABEL'|translate }}</label>
                                <input id="signin-email" type="email" class="form-control"
                                    [class.is-invalid]="forgotPasswordForm.controls['email']|isInvalid"
                                    placeholder="ad@ornek.com" formControlName="email">
                                <div class="invalid-feedback">
                                    <div *ngIf="forgotPasswordForm.controls['email']|hasError:'required'">
                                        {{ 'ERROR_FORM_REQUIRED'|translate }}
                                    </div>
                                    <div *ngIf="forgotPasswordForm.controls['email']|hasError:'email'">
                                        {{ 'ERROR_FORM_INCORRECT_EMAIL'|translate }}
                                    </div>
                                </div>
                            </div>

                            <div class="form-group" *ngIf="showMail">
                                <label for="email-code">{{ 'INPUT_CODE_LABEL'|translate }}</label>
                                <input id="signin-email" type="text" class="form-control"
                                    [class.is-invalid]="forgotPasswordForm.controls['mailCode']|isInvalid"
                                    placeholder="******" formControlName="mailCode" maxlength="5">
                                <div class="invalid-feedback">
                                    <div *ngIf="forgotPasswordForm.controls['mailCode']|hasError:'required'">
                                        {{ 'ERROR_FORM_IN_NOT_CODE'|translate }}
                                    </div>
                                </div>
                                <label for="password">{{ 'INPUT_PASSWORD_NEW_LABEL'|translate }}</label>
                                <input id="signin-email" type="password" class="form-control"
                                    [class.is-invalid]="forgotPasswordForm.controls['password']|isInvalid"
                                    placeholder="******" formControlName="password">
                                <div class="invalid-feedback">
                                    <div *ngIf="forgotPasswordForm.controls['password']|hasError:'required'">
                                        {{ 'ERROR_FORM_IN_NOT_CODE'|translate }}
                                    </div>
                                </div>
                            </div>

                            <div class="form-group mb-0">
                                <button class="btn btn-primary mt-3" *ngIf="showMail && !hasCode"
                                    [disabled]="!hasCode">{{count}}
                                </button>
                                <button class="btn btn-warning mt-3" *ngIf="showMail && hasCode" (click)="sendMail()"
                                    [disabled]="!hasCode">kod
                                    almadım
                                </button>
                            </div>
                            <div class="form-group mb-0">
                                <button type="submit" class="btn btn-primary mt-3" *ngIf="!showMail"
                                    [class.btn-loading]="sendMailCodeProgress" (click)="sendMail()">
                                    {{ 'SEND_CODE'|translate }}
                                </button>
                                <button type="submit" class="btn btn-primary mt-3" *ngIf="showMail"
                                    [class.btn-loading]="sendCodeProgress" (click)="sendMailCode()">
                                    {{ 'RESET_PASSWORD'|translate }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-block-space layout="before-footer"></app-block-space>