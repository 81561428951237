import { NgModule } from '@angular/core';

// modules (angular)
import { CommonModule } from '@angular/common';
import { UrlSerializer } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// modules (third-party)
import { NgxPaginationModule } from 'ngx-pagination';
import { PaginationModule } from 'ngx-bootstrap/pagination';

// directives
import { LazyImgDirective } from './_directives/lazy-img.directive';

// pipes
import { LowerCasePipe } from './_pipes/lower-case.pipe';

// functions
import { LowerCaseUrlSerializer } from './_functions/lowercaseurlserializer';

// services
import { CartManagerService } from './_services/cart-manager.service';
import { TokenInterceptor } from './_services/token.interceptor';

@NgModule({
  declarations: [
    // directives
    LazyImgDirective,
    // pipes
    LowerCasePipe,
  ],
  exports: [
    // directives
    LazyImgDirective,
    // pipes
    LowerCasePipe,
  ],
  imports: [
    // modules (angular)
    CommonModule,
    HttpClientModule,
    NgxPaginationModule,
    PaginationModule.forRoot(),
  ],
  providers: [
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    CartManagerService
  ],
})
export class _SharedModule { }