import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountApi, BlogApi, CountriesApi, ShopApi, VehicleApi, HamburgerMenuApi, GeneralMenuApi, MobileMenuApi } from '../base';
import { FakeAccountApi } from './fake-account.api';
import { FakeBlogApi } from './fake-blog.api';
import { FakeCountriesApi } from './fake-countries.api';
import { FakeShopApi } from './fake-shop.api';
import { FakeVehicleApi } from './fake-vehicle.api';
import { FakeHamburgerMenuApi } from './fake-hamburger-menu.api'
import { FakeGeneralMenuApi } from './fake-general-menu.api'
import { FakeMobileMenuApi } from './fake-mobile-menu.api';

@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        { provide: AccountApi, useClass: FakeAccountApi },
        { provide: BlogApi, useClass: FakeBlogApi },
        { provide: CountriesApi, useClass: FakeCountriesApi },
        { provide: ShopApi, useClass: FakeShopApi },
        { provide: VehicleApi, useClass: FakeVehicleApi },
        { provide: HamburgerMenuApi, useClass: FakeHamburgerMenuApi },
        { provide: GeneralMenuApi, useClass: FakeGeneralMenuApi },
        { provide: MobileMenuApi, useClass: FakeMobileMenuApi }
    ],
})
export class FakeApiModule { }