import { Injectable } from "@angular/core";
import { lastValueFrom, Observable, Subject } from "rxjs";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})

export class CartManagerService {
  private cartCounterSubscriber: Subject<void> = new Subject<void>();
  private successFulPageStatus: boolean = false;

  constructor(
    private api: ApiService) {
  }

  getCartItemAddedEvent(): Observable<any> {
    return this.cartCounterSubscriber.asObservable()
  }

  setCartItemAddedEvent() {
    this.cartCounterSubscriber.next();
  }

  getSuccessFulPageStatus() {
    return this.successFulPageStatus;
  }

  setSuccessFulPageStatus(value: any) {
    this.successFulPageStatus = value;
  }

  async getCartItemCount(): Promise<number> {
    return await lastValueFrom(this.api.get("cart/cartitemcountbyuserid"))
      .then((res) => <number>res)
      .catch((error) => {
        return undefined;
      });
  }

}