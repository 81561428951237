import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HamburgerMenu } from 'src/app/_shared/_interfaces/hamburger-menu';
import { HamburgerMenuApi } from '../base';
import { ImplementationHamburgerMenuApi } from '../implementation-api/implemantation-hamburger-menu.api';

@Injectable()
export class FakeHamburgerMenuApi extends HamburgerMenuApi {

    constructor(
        private impHamburgerMenuApi: ImplementationHamburgerMenuApi) {
        super();
    }

    getHamburgerMenuBrandWithModel(): Observable<HamburgerMenu[]> {
        return this.impHamburgerMenuApi.getHamburgerMenuBrandWithModel();
    }

}