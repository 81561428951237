<app-block-space layout="spaceship-ledge-height"></app-block-space>

<div class="block">
    <div class="container">
        <div class="not-found">
            <div class="not-found__404">
                {{ 'PAGE.404.TEXT_OOPS_ERROR_404' |translate }}
            </div>

            <div class="not-found__content">
                <h1 class="not-found__title">{{ 'PAGE.404.TEXT_PAGE_NOT_FOUND' |translate }}</h1>

                <p class="not-found__text">
                    {{ 'PAGE.404.TEXT_WE_CANT_SEEM_TO_FİND_THE_PAGE_YOURE_LOOKING_FOR' |translate }}<br>
                    {{ 'PAGE.404.TEXT_TRY_TO_USE_THE_SEARCH' |translate }}
                </p>

                <form class="not-found__search">
                    <input type="text" class="not-found__search-input form-control" placeholder="{{ 'PAGE.404.TEXT_SEARCH_QUERY' |translate }}">
                    <button type="submit" class="not-found__search-button btn btn-primary">{{ 'PAGE.404.TEXT_SEARCH' |translate }}</button>
                </form>

                <p class="not-found__text">
                    {{ 'PAGE.404.TEXT_OR_GO_TO_THE_HOME_PAGE_TO_START_OVER' |translate }}
                </p>

                <a class="btn btn-secondary btn-sm" [routerLink]="url.home()">{{ 'PAGE.404.TEXT_GO_TO_HOME_PAGE' |translate }}</a>
            </div>
        </div>
    </div>
</div>

<app-block-space layout="before-footer"></app-block-space>
