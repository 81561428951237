import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { mainMenu } from '../../../../../data/header-main-menu';
import { MainMenuLink } from '../../../../interfaces/main-menu-link';
import { HeaderService } from '../../../../services/header.service';
import { takeUntil } from 'rxjs/operators';
import { GeneralMenuApi } from 'src/app/api';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    items: MainMenuLink[] = mainMenu;
    itemsNew: MainMenuLink[];

    hoveredItem: MainMenuLink | null = null;

    @HostBinding('class.main-menu') classMainMenu = true;

    constructor(
        public header: HeaderService,
        private generalmenu: GeneralMenuApi
    ) { }

    ngOnInit(): void {
        this.generalmenu.getGeneralMenuBrandWithModel().pipe(takeUntil(this.destroy$)).subscribe((x) => {
            let myArray = [];
            x.forEach((element) => {
                let myObject = {
                    title: element.brandName,
                    url: `/shop/category/${element.brandSlug.toLocaleLowerCase()}/products`,
                    submenu: {
                        type: 'menu',
                        links: [
                            ...element.navBarModelItems.map((x) => {
                                return { title: x.modelName, url: `/shop/category/${x.modelSlug?.toLocaleLowerCase()}/products`, picture: "https://csrmoto.com.tr/" + x.modelImage }
                            })
                        ]
                    },
                }
                myArray.push(myObject)
            })
            this.itemsNew = myArray.slice(0,7);
        })
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onItemEnter(item: any): void {
        this.hoveredItem = item;
    }

    onItemLeave(item: any): void {
        if (this.hoveredItem === item) {
            this.hoveredItem = null;
        }
    }

    onItemClick(): void {
        this.hoveredItem = null;
    }

    isVisible(item: MainMenuLink): boolean {
        return !item.customFields
            || !item.customFields['ignoreIn']
            || !item.customFields['ignoreIn'].includes(this.header.desktopLayout);
    }
}
