export interface Pagination {
  pageNumber: number;
  pageSize: number;
  totalItems?: number;
  totalPages?: number;
  searchText?: string;
  orderby?: string;
}

export class PaginatedResult<T> {
  result: T;
  pagination: Pagination;
}
