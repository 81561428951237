import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt'
import { Observable, Subject } from 'rxjs';

export const TOKEN_NAME: string = 'token';
export const USER_NO: string = 'no';
export const DEVICE_NO: string = 'deviceno';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  private loginsubject: Subject<void> = new Subject<void>();
  private logOutsubject: Subject<void> = new Subject<void>();
  private profileUpdateSubscriber: Subject<void> = new Subject<void>();

  decodedToken: any;
  jwtHelper = new JwtHelperService()

  constructor() { }

  getToken(): string {
    return localStorage.getItem(TOKEN_NAME);
  }

  setToken(token: string): void {
    localStorage.setItem(TOKEN_NAME, token);
  }

  loggedIn() {
    const token = localStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token);
  }

  getDecodedToken() {
    if (localStorage.getItem("token") == null) {
      return null;
    }
    return this.jwtHelper.decodeToken(localStorage.getItem("token"));
  }

  getLoginEvent(): Observable<any> {
    return this.loginsubject.asObservable();
  }

  loginEvent() {
    this.loginsubject.next();
  }

  getLogoutEvent(): Observable<any> {
    return this.logOutsubject.asObservable();
  }

  logOutEvent() {
    this.getLogoutEvent();
    this.decodedToken = null;
    this.logOutsubject.next();
  }

  getUserNameSurname() {
    return localStorage.getItem("userNameSurname")
  }

  getProfileUpdateEvent(): Observable<any> {
    return this.profileUpdateSubscriber.asObservable();
  }

  profileUpdateEvent() {
    this.profileUpdateSubscriber.next();
  }

}