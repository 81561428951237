<div class="card">
    <div class="card-header">
        <h5>{{ 'HEADER_EDIT_PROFILE'|translate }}</h5>
    </div>
    <div class="card-divider"></div>
    <div class="card-body card-body--padding--2">
        <div class="row no-gutters">
            <div class="col-12 col-lg-7 col-xl-6" [formGroup]="form">
                <div class="form-group">
                    <label for="profile-userName">{{ 'INPUT_FIRST_NAME_LABEL'|translate }}</label>
                    <input type="text" id="profile-userName" formControlName="userName" class="form-control"
                        [class.is-invalid]="form.controls['userName']|isInvalid"
                        [placeholder]="'INPUT_FIRST_NAME_PLACEHOLDER'|translate">
                    <div class="invalid-feedback">
                        <div *ngIf="form.controls['userName']|hasError:'required'">
                            {{ 'ERROR_FORM_REQUIRED'|translate }}
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="profile-userSurname">{{ 'INPUT_LAST_NAME_LABEL'|translate }}</label>
                    <input type="text" id="profile-userSurname" formControlName="userSurname" class="form-control"
                        [class.is-invalid]="form.controls['userSurname']|isInvalid"
                        [placeholder]="'INPUT_LAST_NAME_PLACEHOLDER'|translate">
                    <div class="invalid-feedback">
                        <div *ngIf="form.controls['userSurname']|hasError:'required'">
                            {{ 'ERROR_FORM_REQUIRED'|translate }}
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="profile-userMail">{{ 'INPUT_EMAIL_ADDRESS_LABEL'|translate }}</label>
                    <input type="email" id="profile-userMail" formControlName="userMail" class="form-control"
                        [class.is-invalid]="form.controls['userMail']|isInvalid"
                        [placeholder]="'INPUT_EMAIL_ADDRESS_PLACEHOLDER'|translate">
                    <div class="invalid-feedback">
                        <div *ngIf="form.controls['userMail']|hasError:'required'">
                            {{ 'ERROR_FORM_REQUIRED'|translate }}
                        </div>
                        <div *ngIf="form.controls['userMail']|hasError:'email'">
                            {{ 'ERROR_FORM_INCORRECT_EMAIL'|translate }}
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="profile-userCompanyName">{{ 'INPUT_COMPANY_LABEL'|translate }}</label>
                    <input type="text" id="profile-userCompanyName" formControlName="userCompanyName"
                        class="form-control" [class.is-invalid]="form.controls['userCompanyName']|isInvalid"
                        [placeholder]="'INPUT_COMPANY_PLACEHOLDER'|translate">
                    <div class="invalid-feedback">
                        <div *ngIf="form.controls['userCompanyName']|hasError:'required'">
                            {{ 'ERROR_FORM_REQUIRED'|translate }}
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="profile-userTaxNumber">{{ 'INPUT_VIN_LABEL'|translate }}</label>
                    <input type="text" id="profile-userTaxNumber" formControlName="userTaxNumber" class="form-control"
                        [class.is-invalid]="form.controls['userTaxNumber']|isInvalid"
                        [placeholder]="'INPUT_VIN_PLACEHOLDER'|translate">
                    <div class="invalid-feedback">
                        <div *ngIf="form.controls['userTaxNumber']|hasError:'required'">
                            {{ 'ERROR_FORM_REQUIRED'|translate }}
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="profile-userPhoneNumber">{{ 'INPUT_PHONE_NUMBER_LABEL'|translate }}</label>
                    <input type="text" id="profile-userPhoneNumber" formControlName="userPhoneNumber"
                        class="form-control" [class.is-invalid]="form.controls['userPhoneNumber']|isInvalid"
                        [placeholder]="'INPUT_PHONE_NUMBER_PLACEHOLDER'|translate"  maxlength="10">
                    <div class="invalid-feedback">
                        <div *ngIf="form.controls['userPhoneNumber']|hasError:'required'">
                            {{ 'ERROR_FORM_REQUIRED'|translate }}
                        </div>
                        <div
                            *ngIf="(form.controls['userPhoneNumber']|hasError:'pattern') || (form.controls['userPhoneNumber']|hasError:'minlength') || (form.controls['userPhoneNumber']|hasError:'maxlength')">
                            {{ 'ERROR_FORM_INCORRECT_PHONE'|translate }}
                        </div>
                    </div>
                </div>

                <div class="form-group mb-0">
                    <button class="btn btn-primary mt-3" [class.btn-loading]="saveInProgress" (click)="save()">
                        {{ 'BUTTON_SAVE'|translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>