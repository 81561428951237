<button class="departments__button" type="button" (click)="onClick()">
    <span class="departments__button-icon"><app-icon icon="menu-16x12"></app-icon></span>
    <span class="departments__button-title">{{ label }}</span>
    <span class="departments__button-arrow"><app-icon icon="arrow-rounded-down-9x6"></app-icon></span>
</button>
<div class="departments__menu">
    <div class="departments__arrow"></div>
    <div class="departments__body" (mouseleave)="onMouseleave()">
        <ul class="departments__list">
            <li class="departments__list-padding" role="presentation" (mouseenter)="onMouseleave()"></li>
            <li
                *ngFor="let item of itemsNew"
                [class.departments__item--has-submenu]="item.submenu"
                [class.departments__item--submenu--megamenu]="item.submenu?.type === 'megamenu'"
                [class.departments__item--hover]="item === currentItem"
                [ngClass]="item.submenu['columns'][0]['links'][0]['links'].length > 0 ? '': 'hide'"
                (mouseenter)="onMouseenter(item)"
            >
                <ng-template #linkContent>
                    {{ item.title }}
                    <span *ngIf="item.submenu" class="departments__item-arrow">
                        <app-icon icon="arrow-rounded-right-7x11"></app-icon>
                    </span>
                </ng-template>

                <a *ngIf="!item.external" [routerLink]="item.url" class="departments__item-link" (click)="onItemClick()">
                    <ng-container *ngTemplateOutlet="linkContent"></ng-container>
                </a>
                <a *ngIf="item.external" [href]="item.url" class="departments__item-link" (click)="onItemClick()">
                    <ng-container *ngTemplateOutlet="linkContent"></ng-container>
                </a>
            </li>
            <li class="departments__list-padding" role="presentation" (mouseenter)="onMouseleave()"></li>
        </ul>
        <div class="departments__menu-container">
            <ng-container *ngFor="let item of itemsNew">
                <ng-container *ngIf="item.submenu">
                    <app-megamenu
                        class="departments__megamenu departments__megamenu--size--{{ item.submenu.size }}"
                        [class.departments__megamenu--open]="item === currentItem"
                        [menu]="item.submenu"
                        (itemClick)="onItemClick()"
                    ></app-megamenu>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
