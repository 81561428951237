// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    paypalClientId: 'AfqNKvCkZ4ExY1NKEB0kwrnqpcX7JfZRC1eNr7787k8WizTyOYIwWYCxLrYry28OlLx6v41bDH1NCtA7',
    //baseUrl: document.getElementsByTagName('base')[0].href + "api/",
    //sourceUrl: document.getElementsByTagName('base')[0].href
    baseUrl: "https://csrmoto.com.tr/api",
    sourceUrl: "https://csrmoto.com.tr/",
    // baseUrl: "https://localhost:44393/api",
    // sourceUrl: "https://localhost:44393"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
