import { Component, HostBinding, Input } from '@angular/core';
import { Text } from 'src/app/interfaces/text';

@Component({
  selector: 'app-texts',
  templateUrl: './texts.component.html',
  styleUrls: ['./texts.component.scss']
})
export class TextsComponent {
  @Input() texts: Text[] = [];

  @HostBinding('class.footer-texts') classFooterTexts = true;

  constructor() { }
}