<div class="dashboard" *ngIf="account.user">
    <div class="dashboard__profile card profile-card">
        <div class="card-body profile-card__body">
            <div class="profile-card__avatar">
                <img [src]="account.user.userAvatar" alt="">
            </div>
            <div class="profile-card__name">{{ account.user.userName }} {{ account.user.userSurname }}</div>
            <div class="profile-card__email">{{ account.user.userMail }}</div>
            <div class="profile-card__edit">
                <a routerLink="/account/profile" class="btn btn-secondary btn-sm">
                    {{ 'BUTTON_EDIT_PROFILE'|translate }}
                </a>
            </div>
        </div>
    </div>

    <div *ngIf="!address" class="dashboard__address card">
        <div class="card-body d-flex justify-content-center align-items-center">
            <div class="text-center w-75">
                <p>{{ 'TEXT_CALL_ADD_ADDRESS'|translate }}</p>

                <a routerLink="/account/addresses/new" class="btn btn-secondary btn-sm">
                    {{ 'BUTTON_ADD_ADDRESS'|translate }}
                </a>
            </div>
        </div>
    </div>

    <ng-container *ngIf="address">
        <ng-template #footer>
            <a [routerLink]="url.addressNew(address)">{{ 'LINK_EDIT_ADDRESS'|translate }}</a>
        </ng-template>

        <app-address-card 
            class="dashboard__address" 
            [_address]="address" 
            [label]="'TEXT_DEFAULT_ADDRESS'|translate"
            [featured]="true" 
            [footer]="footer">
        </app-address-card>
    </ng-container>

    <div *ngIf="orders.length > 0" class="dashboard__orders card">
        <div class="card-header">
            <h5>{{ 'HEADER_RECENT_ORDERS'|translate }}</h5>
        </div>
        <div class="card-divider"></div>
        <div class="card-table">
            <div class="table-responsive-sm">
                <table>
                    <thead>
                        <tr>
                            <th>{{ 'TABLE_NUMBER'|translate }}</th>
                            <th>{{ 'TABLE_DATE'|translate }}</th>
                            <th>{{ 'TABLE_STATUS'|translate }}</th>
                            <th>{{ 'TABLE_TOTAL'|translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let order of orders">
                            <td>
                                <a [routerLink]="url.orderNew(order)">
                                    {{ 'FORMAT_ORDER_NUMBER'|translate:{number: order.orderUniqId | slice:0:7} }}
                                </a>
                            </td>
                            <td>{{ 'FORMAT_DATE_MEDIUM'|translate:{date: order.orderRd} }}</td>
                            <td>{{ 'TEXT_ORDER_STATUS_' + order.orderStatus|translate }}</td>
                            <td>{{ 'TEXT_ORDER_TOTAL'|translate:{total: order.orderTotalAmount|currencyFormat, quantity:
                                order.orderQuantity} }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>