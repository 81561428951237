<div class="addresses-list">
    <a routerLink="/account/addresses/new" class="addresses-list__item addresses-list__item--new">
        <div class="addresses-list__plus"></div>
        <div class="btn btn-secondary btn-sm">{{ 'BUTTON_ADD_NEW'|translate }}</div>
    </a>
    <div class="addresses-list__divider"></div>

    <ng-container *ngFor="let _address of _addresses">
        <ng-template #footer>
            <a [routerLink]="url.addressNew(_address)">{{ 'LINK_EDIT_ADDRESS'|translate }}</a>&nbsp;&nbsp;
            <a href="" (click)="$event.preventDefault(); remove(_address);">{{ 'LINK_REMOVE'|translate }}</a>
        </ng-template>

        <app-address-card
            class="addresses-list__item"
            [_address]="_address"
            [label]="_address.addressIsDefault ? ('TEXT_DEFAULT_ADDRESS'|translate) : ''"
            [footer]="footer"
            [loading]="removeInProgress.includes(_address.id)"
        ></app-address-card>

        <div class="addresses-list__divider"></div>
    </ng-container>
</div>
