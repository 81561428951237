<ng-container [formGroup]="form">
    <!-- <div class="form-row">
        <div class="form-group col-md-6">
            <label for="{{ formId }}-userName">{{ 'INPUT_FIRST_NAME_LABEL'|translate }}</label>
            <input
                type="text"
                class="form-control"
                id="{{ formId }}-userName"
                formControlName="userName"
                [class.is-invalid]="form.controls['userName']|isInvalid"
                [placeholder]="'INPUT_FIRST_NAME_PLACEHOLDER'|translate"
            >
            <div class="invalid-feedback">
                <div *ngIf="form.controls['userName']|hasError:'required'">
                    {{ 'ERROR_FORM_REQUIRED'|translate }}
                </div>
            </div>
        </div>
        <div class="form-group col-md-6">
            <label for="{{ formId }}-userSurname">{{ 'INPUT_LAST_NAME_LABEL'|translate }}</label>
            <input
                type="text"
                class="form-control"
                id="{{ formId }}-userSurname"
                formControlName="userSurname"
                [class.is-invalid]="form.controls['userSurname']|isInvalid"
                [placeholder]="'INPUT_LAST_NAME_PLACEHOLDER'|translate"
            >
            <div class="invalid-feedback">
                <div *ngIf="form.controls['userSurname']|hasError:'required'">
                    {{ 'ERROR_FORM_REQUIRED'|translate }}
                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="form-group">
        <label for="{{ formId }}-userCompanyName">{{ 'INPUT_COMPANY_LABEL'|translate }} <span class="text-muted">({{ 'TEXT_OPTIONAL'|translate }})</span></label>
        <input
            type="text"
            class="form-control"
            id="{{ formId }}-userCompanyName"
            formControlName="userCompanyName"
            [placeholder]="'INPUT_COMPANY_PLACEHOLDER'|translate"
        >
    </div> -->
    <!-- <div class="form-group">
        <label for="{{ formId }}-country">{{ 'INPUT_COUNTRY_LABEL'|translate }}</label>
        <select
            id="{{ formId }}-country"
            class="form-control"
            formControlName="country"
            [class.is-invalid]="form.controls['country']|isInvalid"
        >
            <option value="">{{ 'INPUT_COUNTRY_PLACEHOLDER'|translate }}</option>
            <option *ngFor="let country of countries" [value]="country.code">
                {{ 'COUNTRY_NAME_' + country.code|translate }}
            </option>
        </select>
        <div class="invalid-feedback">
            <div *ngIf="form.controls['country']|hasError:'required'">
                {{ 'ERROR_FORM_REQUIRED'|translate }}
            </div>
        </div>
    </div> -->
    <div class="form-group">
        <label for="{{ formId }}-addressTitle">{{ 'INPUT_STREET_ADDRESS_TITLE'|translate }}</label>
        <input type="text" class="form-control" id="{{ formId }}-addressTitle" formControlName="addressTitle"
            [class.is-invalid]="form.controls['addressTitle']|isInvalid"
            [placeholder]="'INPUT_STREET_ADDRESS_TITLE_PLACEHOLDER'|translate">
        <div class="invalid-feedback">
            <div *ngIf="form.controls['addressTitle']|hasError:'required'">
                {{ 'ERROR_FORM_REQUIRED'|translate }}
            </div>
        </div>

    </div>
    <div class="form-group">
        <label for="{{ formId }}-addressDescription">{{ 'INPUT_STREET_ADDRESS_LABEL'|translate
        }}</label>
    <input type="text" class="form-control" id="{{ formId }}-addressDescription"
        formControlName="addressDescription" [placeholder]="'INPUT_STREET_ADDRESS_PLACEHOLDER_2'|translate"
        [class.is-invalid]="form.controls['addressDescription']|isInvalid">
        <div class="invalid-feedback">
            <div *ngIf="form.controls['addressDescription']|hasError:'required'">
                {{ 'ERROR_FORM_REQUIRED'|translate }}
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="{{ formId }}-provinceName">{{ 'INPUT_CITY_LABEL'|translate }}</label>
            <!-- <input
            type="text"
            class="form-control"
            id="{{ formId }}-provinceName"
            formControlName="provinceName"
            [class.is-invalid]="form.controls['provinceName']|isInvalid"
            [placeholder]="'INPUT_CITY_PLACEHOLDER'|translate"
        > -->
            <select id="{{ formId }}-provinceName" class="form-control" formControlName="provinceId" (change)="provinceOnChange($event.target.value)"
            [class.is-invalid]="form.controls['provinceId']|isInvalid">
                <option value="">İl Seçiniz</option>
                <option *ngFor="let i of provinces" value="{{i.id}}">{{i.provinceName}}</option>
            </select>
            <div class="invalid-feedback">
                <div *ngIf="form.controls['provinceId']|hasError:'required'">
                    {{ 'ERROR_FORM_REQUIRED'|translate }}
                </div>
            </div>
        </div>
        <div class="form-group col-md-6">
            <label for="{{ formId }}-districtName">{{ 'INPUT_STATE_LABEL'|translate }}</label>
            <!-- <input
            type="text"
            class="form-control"
            id="{{ formId }}-districtName"
            formControlName="districtName"
            [class.is-invalid]="form.controls['districtName']|isInvalid"
            [placeholder]="'INPUT_STATE_PLACEHOLDER'|translate"
        > -->
            <select id="{{ formId }}-provinceName" class="form-control" formControlName="districtId"
            [class.is-invalid]="form.controls['districtId']|isInvalid">
                <option value="">İlçe Seçiniz</option>
                <option *ngFor="let i of districts" value="{{i.id}}">{{i.districtName}}</option>
            </select>
            <div class="invalid-feedback">
                <div *ngIf="form.controls['districtId']|hasError:'required'">
                    {{ 'ERROR_FORM_REQUIRED'|translate }}
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="form-group">
        <label for="{{ formId }}-postcode">{{ 'INPUT_POSTCODE_LABEL'|translate }}</label>
        <input
            type="text"
            class="form-control"
            id="{{ formId }}-postcode"
            formControlName="postcode"
            [class.is-invalid]="form.controls['postcode']|isInvalid"
            [placeholder]="'INPUT_POSTCODE_PLACEHOLDER'|translate"
        >
        <div class="invalid-feedback">
            <div *ngIf="form.controls['postcode']|hasError:'required'">
                {{ 'ERROR_FORM_REQUIRED'|translate }}
            </div>
        </div>
    </div> -->
    <div class="form-row">
        <!-- <div class="form-group col-md-6 mb-0">
            <label for="{{ formId }}-userMail">{{ 'INPUT_EMAIL_ADDRESS_LABEL'|translate }}</label>
            <input
                type="userMail"
                class="form-control"
                id="{{ formId }}-userMail"
                formControlName="userMail"
                [class.is-invalid]="form.controls['userMail']|isInvalid"
                [placeholder]="'INPUT_EMAIL_ADDRESS_PLACEHOLDER'|translate"
            >
            <div class="invalid-feedback">
                <div *ngIf="form.controls['userMail']|hasError:'required'">
                    {{ 'ERROR_FORM_REQUIRED'|translate }}
                </div>
                <div *ngIf="form.controls['userMail']|hasError:'email'">
                    {{ 'ERROR_FORM_INCORRECT_EMAIL'|translate }}
                </div>
            </div>
        </div>
        <div class="form-group col-md-6 mb-0">
            <label for="{{ formId }}-userPhoneNumber">{{ 'INPUT_PHONE_NUMBER_LABEL'|translate }}</label>
            <input
                type="text"
                class="form-control"
                id="{{ formId }}-userPhoneNumber"
                formControlName="userPhoneNumber"
                [class.is-invalid]="form.controls['userPhoneNumber']|isInvalid"
                [placeholder]="'INPUT_PHONE_NUMBER_PLACEHOLDER'|translate"
            >
            <div class="invalid-feedback">
                <div *ngIf="form.controls['userPhoneNumber']|hasError:'required'">
                    {{ 'ERROR_FORM_REQUIRED'|translate }}
                </div>
            </div>
        </div> -->

        <div class="form-group col-md-12">
            <label for="{{ formId }}-addressType">{{ 'INPUT_ADDRESS_TYPE_LABEL'|translate }}</label>
            <!-- <input
                type="text"
                class="form-control"
                id="{{ formId }}-districtName"
                formControlName="districtName"
                [class.is-invalid]="form.controls['districtName']|isInvalid"
                [placeholder]="'INPUT_STATE_PLACEHOLDER'|translate"
            > -->
            <select id="{{ formId }}-addressType" class="form-control" formControlName="addressType"
            [class.is-invalid]="form.controls['addressType']|isInvalid">
                <option value="">Adres Tipi Seçiniz</option>
                <option value="teslimat">Teslimat</option>
                <option value="fatura">Fatura</option>
            </select>
            <div class="invalid-feedback">
                <div *ngIf="form.controls['addressType']|hasError:'required'">
                    {{ 'ERROR_FORM_REQUIRED'|translate }}
                </div>
            </div>
        </div>
    </div>
</ng-container>