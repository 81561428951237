import { Observable } from 'rxjs';
import { User } from '../../interfaces/user';
import { _User, UserData } from '../../_shared/_interfaces/user'
import { Address, AddressData } from '../../interfaces/address';
import { OrdersList, _OrderList } from '../../interfaces/list';
import { Order } from '../../interfaces/order';
import { AuthenticationResult } from 'src/app/_shared/_interfaces/authenticationResult';
import { _Order } from 'src/app/_shared/_interfaces/order';
import { _Address, _AddressData } from 'src/app/_shared/_interfaces/address';
import { Province } from 'src/app/_shared/_interfaces/province';
import { District } from 'src/app/_shared/_interfaces/district';

export interface EditProfileData {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

export interface GetOrdersListOptions {
    page?: number;
    limit?: number;
    sort?: string;
}

export interface EditAddressData extends AddressData {
    default: boolean;
}

export interface EditAddressDataNew extends _AddressData {
    addressIsDefault: boolean;
}

export interface EditUserData extends UserData {
    userName: string;
    userSurname: string;
    userMail: string;
    userCompanyName: string;
    userTaxNumber: string;
    userPhoneNumber: string;
    password: string;
}

export interface EditProfileDataNew {
    userName: string;
    userSurname: string;
    userMail: string;
    userCompanyName: string;
    userTaxNumber: string;
    userPhoneNumber: string;
    userAllowSms: boolean;
    userAllowEmail: boolean;
}

export interface ChangePasswordData {
    oldPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
}

export interface AddNewsletterData {
    email: string;
}

export abstract class AccountApi {
    abstract user: User | null;

    abstract user$: Observable<User | null>;

    abstract signIn(email: string, password: string): Observable<User>;

    abstract signInNew(email: string, password: string): Observable<AuthenticationResult>;

    abstract signUp(email: string, password: string): Observable<User>;

    abstract signUpNew(data: EditUserData): Observable<_User>;

    abstract signOut(): Observable<void>;

    abstract editProfile(data: EditProfileData): Observable<User>;

    abstract editProfileNew(userId: number, data: EditProfileDataNew): Observable<_User>;

    abstract changePassword(oldPassword: string, newPassword: string): Observable<void>;

    abstract changePasswordNew(data: ChangePasswordData): Observable<string>;

    abstract addNewsletterNew(data: AddNewsletterData): Observable<void>;

    abstract addAddress(data: EditAddressData): Observable<Address>;

    abstract addAddressNew(data: EditAddressDataNew): Observable<_Address>;

    abstract editAddress(addressId: number, data: EditAddressData): Observable<Address>;

    abstract editAddressNew(addressId: number, data: EditAddressDataNew): Observable<_Address>;

    abstract delAddress(addressId: number): Observable<void>;

    abstract delAddressNew(addressId: number): Observable<void>;

    abstract getDefaultAddress(): Observable<Address>;

    abstract getDefaultAddressNew(): Observable<_Address[]>;

    abstract getAddress(addressId: number): Observable<Address>;

    abstract getAddressNew(addressId: number): Observable<_Address>;

    abstract getAddresses(): Observable<Address[]>;

    abstract getAddressesNew(): Observable<_Address[]>;

    abstract getOrdersList(options?: GetOrdersListOptions): Observable<OrdersList>;

    abstract getOrdersListNew(options?: GetOrdersListOptions): Observable<_OrderList>;

    abstract getOrderById(id: number): Observable<Order>;
    abstract getOrderByIdNew(id: string): Observable<_Order>;

    abstract getOrderByToken(token: string): Observable<Order>;

    abstract getProvinces():Observable<Province[]>

    abstract getDistricts(provinceId: number):Observable<District[]>

}
