import { Component } from '@angular/core';
import { theme } from 'src/data/theme';

@Component({
    selector: 'app-page-privacy-policy',
    templateUrl: './page-privacy-policy.component.html',
    styleUrls: ['./page-privacy-policy.component.scss'],
})
export class PagePrivacyPolicyComponent {

    theme = theme;
    constructor() { }
}
