<div class="site-footer">
    <app-decor class="site-footer__decor" type="bottom"></app-decor>
    <div class="site-footer__widgets">
        <div class="container">
            <div class="row" *ngIf="theme.styles.footer_widget[0] == 'four'">
                <div class="col-12 col-xl-4">
                    <app-contacts class="site-footer__widget"></app-contacts>
                </div>
                <div class="col-6 col-md-3 col-xl-2">
                    <app-links
                        class="site-footer__widget"
                        [header]="'HEADER_INFORMATION'|translate"
                        [links]="[
                            {title: 'LINK_ABOUT_US'|translate, url: '/about-us'},
                            {title: 'LINK_DELIVERY_INFORMATION'|translate, url: '/delivery-information'},
                            {title: 'LINK_PRIVACY_POLICY'|translate, url: '/privacy-policy'},
                            {title: 'LINK_CONTACT_US'|translate, url: '/contact-us'},
                            {title: 'LINK_RETURNS'|translate, url: '/returns'},
                            {title: 'LINK_SITE_MAP'|translate, url: '/sitemap.xml'}
                        ]"
                    ></app-links>
                </div>
                <div class="col-6 col-md-3 col-xl-2">
                    <app-links
                        class="site-footer__widget"
                        [header]="'HEADER_MY_ACCOUNT'|translate"
                        [links]="[
                            {title: 'LINK_DASHBOARD'|translate, url: '/account/dashboard'},
                            {title: 'LINK_PROFILE'|translate, url: '/account/profile'},
                            {title: 'LINK_ORDER_HISTORY'|translate, url: '/account/orders'},
                            {title: 'LINK_ADDRESSES'|translate, url: '/account/addresses'},
                            {title: 'LINK_PASSWORD_CHANGE'|translate, url: '/account/password'}
                        ]"
                    ></app-links>
                </div>
                <div class="col-12 col-md-6 col-xl-4">
                    <app-newsletter class="site-footer__widget"></app-newsletter>
                </div>
            </div>
            <div class="row" *ngIf="theme.styles.footer_widget[0] == 'one'">
                <div class="col-12 col-xl-12">
                    <app-texts 
                    class="site-footer__widget"
                    [texts]="[
                        {content: '100 USD Altı Alışverişleriniz Bekletilecektir!'}
                    ]"
                    ></app-texts>
            </div>
            </div>
        </div>
    </div>
    <div class="site-footer__bottom">
        <div class="container">
            <div class="site-footer__bottom-row">
                <div class="site-footer__copyright">
                    <!-- copyright -->
                    Copyright © {{ theme.extra.copyright_year[0]}} <a href="http://csrmoto.com.tr/" target="_blank">Özyıldız Motor</a> —
                    {{ 'TEXT_DESIGNED_BY' |translate }} <a href="{{ theme.author.profile_url }}" target="_blank">{{ theme.author.name }}</a>
                    <!-- copyright / end -->
                </div>
                <div class="site-footer__payments">
                    <img src="assets/images/payments.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
