import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, throwError } from "rxjs";
import { _OrderList } from "src/app/interfaces/list";
import { _Order } from "src/app/_shared/_interfaces/order";
import { environment } from "src/environments/environment";
import { GetOrdersListOptions } from "../base";

function error(message: string): HttpErrorResponse {
    return new HttpErrorResponse({
        status: 400,
        statusText: 'Bad Request',
        error: { message },
    });
}

@Injectable({
    providedIn: 'root'
})

export class ImplementationOrderApi {
    baseURL: string = environment.baseUrl + "/";

    constructor(
        private http: HttpClient) {
    }



 getOrdersListNew(options?: GetOrdersListOptions): Observable<_OrderList> {
        options = options || {};

        let orderfilter = {
            pageNumber:options.page,
            pageSize:options.limit,
            orderBy:'timedesc'
        }

        return this.http.post<_OrderList>(this.baseURL + "order/paged",orderfilter).pipe(

            catchError(err => {
                if (err.error == "Invalid Email address or Password.") {
                    return throwError(() => error('AUTH_WRONG_PASSWORD'))
                }
                return throwError(() => err);
            }),
        );
    }


    getOrderByIdNew(id:string){

        return this.http.get<_Order>(this.baseURL + "orderdetail/detail/"+id).pipe(

            catchError(err => {
                if (err.error == "Invalid Email address or Password.") {
                    return throwError(() => error('AUTH_WRONG_PASSWORD'))
                }
                return throwError(() => err);
            }),
        );
    }


}
