import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MobileMenu } from 'src/app/_shared/_interfaces/mobile-menu';
import { MobileMenuApi } from '../base';
import { ImplementationMobileMenuApi } from '../implementation-api/implemantation-mobile-menu.api';

@Injectable()
export class FakeMobileMenuApi extends MobileMenuApi {

    constructor(
        private impMobileMenuApi: ImplementationMobileMenuApi) {
        super();
    }

    getMobileMenuBrandWithModel(): Observable<MobileMenu[]> {
        return this.impMobileMenuApi.getMobileMenuBrandWithModel();
    }

}