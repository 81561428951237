<ng-container appAddToCart appAddToWishlist appAddToCompare #cart="addToCart" #wishlist="addToWishlist"
    #compare="addToCompare">
    <div class="product-card__actions-list">
        <button class="product-card__action product-card__action--quickview" type="button"
            [class.product-card__action--loading]="showingQuickview" [attr.aria-label]="'BUTTON_QUICKVIEW'|translate"
            (click)="showQuickview()">
            <app-icon icon="quickview-16"></app-icon>
        </button>
        <ng-container *ngIf="!exclude.includes('actions')">
            <!-- <button type="button" class="product-card__action product-card__action--wishlist"
                [class.product-card__action--loading]="wishlist.inProgress"
                [attr.aria-label]="'BUTTON_ADD_TO_WISHLIST'|translate" (click)="wishlist.add(product)">
                <app-icon icon="wishlist-16"></app-icon>
            </button> -->
            <button type="button" class="product-card__action product-card__action--compare"
                [class.product-card__action--loading]="compare.inProgress"
                [attr.aria-label]="'BUTTON_ADD_TO_COMPARE'|translate" (click)="compare.add(product)">
                <app-icon icon="compare-16"></app-icon>
            </button>
        </ng-container>
    </div>

    <div class="product-card__image">
        <div class="image image--type--product">
            <a [routerLink]="url.product(product)" class="image__body">
                <img class="image__tag" [src]="product|getProductImage" [alt]="product.name" imageLoader>
            </a>
        </div>

        <app-status-badge *ngIf="!exclude.includes('status-badge') && vehicle" class="product-card__fit"
            [type]="compatibility()|compatibilityToStatusBadgeIcon"
            [icon]="compatibility()|compatibilityToStatusBadgeIcon"
            [text]="compatibility()|compatibilityToStatusBadgeText|translate:{year: vehicle.year, make: vehicle.make, model: vehicle.model}"
            [tooltipContent]="compatibility()|compatibilityToStatusBadgeText|translate:{year: vehicle.year, make: vehicle.make, model: vehicle.model}"></app-status-badge>
    </div>

    <div class="product-card__info">
        <!-- <div *ngIf="!exclude.includes('meta')" class="product-card__meta">
            <span class="product-card__meta-title">{{ 'TEXT_PNO'|translate }}:</span> {{ product.partNumber }}
        </div> -->

        <div class="product-card__name">
            <div>
                <div *ngIf="product?.badges?.length" class="product-card__badges">
                    <div *ngFor="let badge of product.badges" class="tag-badge tag-badge--{{ badge }}">{{ badge }}</div>
                </div>
                <a [routerLink]="url.product(product)">{{ product.name }}</a>
            </div>
        </div>

        <!-- <div class="product-card__rating">
            <app-rating class="product-card__rating-stars" [value]="product.rating || 0"></app-rating>
            <div class="product-card__rating-label">
                {{ 'TEXT_RATING_LABEL'|translate:{rating: product.rating, reviews: product.reviews} }}
            </div>
        </div> -->

        <div class="product-card__brand">
            <!-- <app-brand class="product-card__brand-stars" [value]="product.brand || 0"></app-brand> -->
            <div class="product-card__brand-label">
                <a routerLink="/shop/category/{{product.brand.name.toLocaleLowerCase()}}/products">{{
                    product.brand.name }}</a>
            </div>
        </div>

        <div class="product-card__code">
            <div class="product-card__code-label">
                <a [routerLink]="url.product(product)">{{
                    product.partNumber }}</a>
            </div>
        </div>

        <div *ngIf="!exclude.includes('features') && featuredAttributes.length > 0" class="product-card__features">
            <ul>
                <li *ngFor="let attribute of featuredAttributes">
                    {{ attribute.name }}:
                    <ng-container *ngFor="let value of attribute.values; let last = last">
                        {{ value.name + (!last ? ', ' : '') }}
                    </ng-container>
                </li>
            </ul>
        </div>
    </div>

    <div class="product-card__footer__before">
        <div class="product-card__prices">
            <ng-container *ngIf="product.compareAtPrice && isAuthenticated">
                <div class="product-card__price product-card__price--new">{{ product.price|currencyFormat }}</div>
                <div *ngIf="product.compareAtPrice != product.price"
                    class="product-card__price product-card__price--old">{{ product.compareAtPrice|currencyFormat }}
                </div>
            </ng-container>
            <ng-container *ngIf="!product.compareAtPrice && isAuthenticated">
                <div class="product-card__price product-card__price--current">{{ product.price|currencyFormat }}</div>
            </ng-container>
            <ng-container *ngIf="!isAuthenticated">
                <div class="product-card__price product-card__price--current">{{
                    'TEXT_SHOWING_LOGIN_FOR_PRICE'|translate }}</div>
            </ng-container>
        </div>
    </div>

    <div class="product-card__footer">
        <div class="product__actions product__actions-fix" [formGroup]="form">
            <ng-container *ngIf="product.stock !== 'out-of-stock'">
                <div class="product__actions-item product__actions-item--quantity">
                    <app-input-number formControlName="quantity" size="lg" [min]="1"></app-input-number>
                </div>
                <div class="product__actions-divider"></div>
            </ng-container>
        </div>
        <!-- <div class="product-card__prices">
            <ng-container *ngIf="product.compareAtPrice && isAuthenticated">
                <div class="product-card__price product-card__price--new">{{ product.price|currencyFormat }}</div>
                <div *ngIf="product.compareAtPrice != product.price"
                    class="product-card__price product-card__price--old">{{ product.compareAtPrice|currencyFormat }}
                </div>
            </ng-container>
            <ng-container *ngIf="!product.compareAtPrice && isAuthenticated">
                <div class="product-card__price product-card__price--current">{{ product.price|currencyFormat }}</div>
            </ng-container>
            <ng-container *ngIf="!isAuthenticated">
                <div class="product-card__price product-card__price--current">{{
                    'TEXT_SHOWING_LOGIN_FOR_PRICE'|translate }}</div>
            </ng-container>
        </div> -->
        <ng-container *ngIf="!exclude.includes('buttons')">
            <!-- <button type="button" class="product-card__addtocart-icon"
                [class.product-card__addtocart-icon--loading]="cart.inProgress"
                [attr.aria-label]="'BUTTON_ADD_TO_CART'|translate" (click)="cart.add(product)">
                <app-icon icon="cart-20"></app-icon>
            </button> -->
            <button type="button" class="product-card__addtocart-icon"
                [class.product-card__addtocart-icon--loading]="addToCartInProgress"
                [attr.aria-label]="'BUTTON_ADD_TO_CART'|translate" (click)="addToCart()">
                <app-icon icon="cart-20"></app-icon>
            </button>
            <ng-container *ngIf="!exclude.includes('list-buttons')">
                <button type="button" class="product-card__addtocart-full"
                    [class.product-card__addtocart-full--loading]="cart.inProgress" (click)="cart.add(product)">
                    {{ 'BUTTON_ADD_TO_CART'|translate }}
                </button>
                <button type="button" class="product-card__wishlist"
                    [class.product-card__wishlist--loading]="wishlist.inProgress" (click)="wishlist.add(product)">
                    <app-icon icon="wishlist-16"></app-icon>
                    <span>{{ 'BUTTON_ADD_TO_WISHLIST'|translate }}</span>
                </button>
                <button type="button" class="product-card__compare"
                    [class.product-card__compare--loading]="compare.inProgress" (click)="compare.add(product)">
                    <app-icon icon="compare-16"></app-icon>
                    <span>{{ 'BUTTON_ADD_TO_COMPARE'|translate }}</span>
                </button>
            </ng-container>
        </ng-container>
    </div>
</ng-container>