import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, distinct, find, map, Observable, of, throwError } from "rxjs";
import { _Address } from "src/app/_shared/_interfaces/address";
import { AuthenticationResult } from "src/app/_shared/_interfaces/authenticationResult";
import { District } from "src/app/_shared/_interfaces/district";
import { _Order } from "src/app/_shared/_interfaces/order";
import { Province } from "src/app/_shared/_interfaces/province";
import { _User } from "src/app/_shared/_interfaces/user";
import { environment } from "src/environments/environment";
import { ChangePasswordData, EditAddressDataNew, EditProfileDataNew, EditUserData } from "../base";

function error(message: string): HttpErrorResponse {
    return new HttpErrorResponse({
        status: 400,
        statusText: 'Bad Request',
        error: { message },
    });
}

@Injectable({
    providedIn: 'root'
})

export class ImplementationAddressApi {
    baseURL: string = environment.baseUrl + "/";
    equalAddressDefault: _Address[] = [];

    constructor(
        private http: HttpClient) {
    }

    addAddressNew(data: EditAddressDataNew): Observable<_Address> {
        return this.http.post<_Address>(this.baseURL + "address/", data)
    }

    editAddressNew(addressId: number, data: EditAddressDataNew): Observable<_Address> {
        return this.http.put<_Address>(this.baseURL + "address/" + addressId, data)
    }

    getAddressNew(addressId: number): Observable<_Address> {
        return this.http.get<_Address>(this.baseURL + "address/" + addressId)
    }

    getAddressesNew(): Observable<_Address[]> {
        return this.http.get<_Address[]>(this.baseURL + "address/byuserid")
    }

    delAddressNew(addressId: number): Observable<void> {
        return this.http.delete<void>(this.baseURL + "address/" + addressId)
    }

    getDefaultAddressNew(): Observable<_Address> {
        this.getAddressesNew().pipe().subscribe(x => {
            this.equalAddressDefault = x;

        })

        return of((this.equalAddressDefault.find(x => x.addressIsDefault) || null))
    }

    getProvinces(): Observable<Province[]> {
        return this.http.get<Province[]>(this.baseURL + "province/all")
    }

    getDistricts(provinceId: number): Observable<District[]> {
        return this.http.get<District[]>(this.baseURL + "district/byprovinceid/" + provinceId)
    }
}
