import { Injectable } from '@angular/core';
import { Category, ShopCategory } from '../interfaces/category';
import { Address } from '../interfaces/address';
import { Order } from '../interfaces/order';
import { Product } from '../interfaces/product';
import { Brand } from '../interfaces/brand';
import { _Order } from '../_shared/_interfaces/order';
import { _Address } from '../_shared/_interfaces/address';

@Injectable({
    providedIn: 'root',
})
export class UrlService {
    constructor() { }

    home(): string {
        return '/';
    }

    shop(): string {
        return '/shop';
    }

    category(category: Category): string {
        if (category.type === 'shop') {
            return this.shopCategory(category);
        }

        return '/';
    }

    shopCategory(category: ShopCategory): string {
        return `/shop/category/${category.slug}` + (category.layout === 'products' ? '/products' : '');
    }

    allProducts(): string {
        return '/shop/category/products';
    }

    allProductsNew(slug:string): string {

        return `/shop/category/${slug}/products`
    }

    product(product: Product): string {
        return `/shop/products/${product.slug}`;
    }

    brand(brand: Brand): string {
        return '/';
    }

    brandNew(brand: string): string {
        return `/${brand}`;
    }

    modelNew(model: string): string {
        return `/${model}`;
    }

    address(address: Address): string {
        return `/account/addresses/${address.id}`;
    }

    addressNew(_address: _Address): string {
        return `/account/addresses/${_address.id}`;
    }

    order(order: Order): string {
        return `/account/orders/${order.id}`;
    }

    orderNew(order: _Order): string {
        return `/account/orders/${order.orderUniqId}`;
    }

    cart(): string {
        return '/shop/cart';
    }

    checkout(): string {
        return '/shop/checkout';
    }

    login(): string {
        return '/account/login';
    }

    contacts(): string {
        return '/contact-us';
    }
}
