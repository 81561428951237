import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AccountApi } from '../../../../api';
import { ActivatedRoute } from '@angular/router';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { Order } from '../../../../interfaces/order';
import { TranslateService } from '@ngx-translate/core';
import { _Order } from 'src/app/_shared/_interfaces/order';

@Component({
    selector: 'app-page-order-details',
    templateUrl: './page-order-details.component.html',
    styleUrls: ['./page-order-details.component.scss'],
})
export class PageOrderDetailsComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    order!: _Order;

    get orderNumber(): string {
        return this.translate.instant('FORMAT_ORDER_NUMBER', { number: this.order?.orderUniqId });
    }

    constructor(
        private route: ActivatedRoute,
        private accountApi: AccountApi,
        private translate: TranslateService,
    ) { }

    ngOnInit(): void {
        const orderPublicId = this.route.snapshot.params.id
        // this.route.params.pipe(
        //     map(x => x['id'] ? parseFloat(x['id']) : this.route.snapshot.data['id'] ),
        //     mergeMap(orderPublicId => this.accountApi.getOrderByIdNew(orderPublicId)),
        //     takeUntil(this.destroy$),
        // ).subscribe(order => {
        //     this.order = order

        // });


        this.accountApi.getOrderByIdNew(orderPublicId).subscribe((data)=>{
            this.order = data
        })



    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
