<div class="timer__part">
    <div class="timer__part-value timer__part-value--days">{{ format(leftDays) }}</div>
    <div class="timer__part-label">{{ 'TEXT_TIMER_DAYS'|translate }}</div>
</div>
<div class="timer__dots"></div>
<div class="timer__part">
    <div class="timer__part-value timer__part-value--hours">{{ format(leftHours) }}</div>
    <div class="timer__part-label">{{ 'TEXT_TIMER_HOURS'|translate }}</div>
</div>
<div class="timer__dots"></div>
<div class="timer__part">
    <div class="timer__part-value timer__part-value--minutes">{{ format(leftMinutes) }}</div>
    <div class="timer__part-label">{{ 'TEXT_TIMER_MINUTES'|translate }}</div>
</div>
<div class="timer__dots"></div>
<div class="timer__part">
    <div class="timer__part-value timer__part-value--seconds">{{ format(leftSeconds) }}</div>
    <div class="timer__part-label">{{ 'TEXT_TIMER_SECONDS'|translate }}</div>
</div>
