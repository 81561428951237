import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, Observable, throwError } from "rxjs";
import { AuthenticationResult } from "src/app/_shared/_interfaces/authenticationResult";
import { _Order } from "src/app/_shared/_interfaces/order";
import { _User } from "src/app/_shared/_interfaces/user";
import { environment } from "src/environments/environment";
import { ChangePasswordData, EditProfileDataNew, EditUserData } from "../base";

function error(message: string): HttpErrorResponse {
    return new HttpErrorResponse({
        status: 400,
        statusText: 'Bad Request',
        error: { message },
    });
}

@Injectable({
    providedIn: 'root'
})

export class ImplementationAccountApi {
    baseURL: string = environment.baseUrl + "/";

    constructor(
        private http: HttpClient) {
    }

    accountSignInNew(email: string, password: string): Observable<AuthenticationResult> {
        return this.http.post<AuthenticationResult>(this.baseURL + "authentication/login", { email: email, password: password }).pipe(
            map(data => (
                {

                user: {
                    id: data.user.id,
                    userName: data.user.userName,
                    userSurname: data.user.userSurname,
                    userMail: data.user.userMail,
                    userPhoneNumber: data.user.userPhoneNumber,
                    userCompanyName: data.user.userCompanyName,
                    userTaxNumber: data.user.userTaxNumber,
                    userAvatar:"//www.gravatar.com/avatar/bde30b7dd579b3c9773f80132523b4c3?d=mp&s=88",
                    userPublicId:data.user.userPublicId
                },
                token: data.token
            })),
            catchError(err => {
                if (err.error == "Invalid Email address or Password.") {
                    return throwError(() => error('AUTH_WRONG_PASSWORD'))
                }
                return throwError(() => err);
            }),
        );
    }

    accountSignUp(data: EditUserData): Observable<_User> {
        if (!/^.+@.+$/.test(data.userMail)) {
            return throwError(() => error('AUTH_INVALID_EMAIL'));
        }

        if (data.userMail === 'test@miraz.com.tr') {
            return throwError(() => error('AUTH_EMAIL_ALREADY_IN_USE'));
        }

        if (data.password.length < 6) {
            return throwError(() => error('AUTH_WEAK_PASSWORD'));
        }

        return this.http.post<_User>(this.baseURL + "authentication/register", data)
    }

    accountEditProfileNew(userId: number, data: EditProfileDataNew): Observable<_User> {
        return this.http.put<_User>(this.baseURL + "user/" + userId, data)
    }

    accountChangePasswordNew(data: ChangePasswordData): Observable<string> {
        if (data.newPassword.length < 6) {
            return throwError(() => error('AUTH_WEAK_PASSWORD'));
        }

        return this.http.post<{}>(this.baseURL + "authentication/changepassword", data).pipe(
            map(()=>''),
            catchError(err => {
                return throwError(() => error(err.error));
            }),

        )
    }

}
