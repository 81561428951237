import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccountApi } from '../../../../api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UrlService } from '../../../../services/url.service';
import { _Order } from 'src/app/_shared/_interfaces/order';
import { _Address } from 'src/app/_shared/_interfaces/address';

@Component({
    selector: 'app-page-dashboard',
    templateUrl: './page-dashboard.component.html',
    styleUrls: ['./page-dashboard.component.scss'],
})
export class PageDashboardComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    address!: _Address;

    orders: _Order[] = [];

    constructor(
        public account: AccountApi,
        public url: UrlService,
    ) { }

    ngOnInit(): void {
        this.account.getDefaultAddressNew().pipe(takeUntil(this.destroy$)).subscribe(x => {
            this.address = x.find(t=> t.addressIsDefault == true && t.addressType == 'fatura')
        });
        this.account.getOrdersListNew({ limit: 3 }).pipe(takeUntil(this.destroy$)).subscribe(x => {
            this.orders = x.items;
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
