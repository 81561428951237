import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountApi } from '../../../../api';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-page-profile',
    templateUrl: './page-profile.component.html',
    styleUrls: ['./page-profile.component.scss'],
})
export class PageProfileComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    form!: FormGroup;
    saveInProgress = false;

    constructor(
        private account: AccountApi,
        private fb: FormBuilder,
        private toastr: ToastrService,
        private translate: TranslateService,
    ) { }

    ngOnInit(): void {
        if (!this.account.user) {
            throw new Error('User is unauthorized');
        }

        this.form = this.fb.group({
            id: [this.account.user.id],
            userName: [this.account.user.userName, [Validators.required]],
            userSurname: [this.account.user.userSurname, [Validators.required]],
            userMail: [{ value: this.account.user.userMail, disabled: true }, [Validators.required, Validators.email]],
            userCompanyName: [this.account.user.userCompanyName, Validators.required],
            userTaxNumber: [this.account.user.userTaxNumber, Validators.required],
            userPhoneNumber: [this.account.user.userPhoneNumber, [Validators.required, Validators.pattern("^[0-9]*$"),
            Validators.minLength(10), Validators.maxLength(10)]],
            userAllowSms: [this.account.user.userAllowSms],
            userAllowEmail: [this.account.user.userAllowEmail],
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    save(): void {
        this.form.markAllAsTouched();

        if (this.saveInProgress || this.form.invalid) {
            return;
        }

        this.saveInProgress = true;

        this.account.editProfileNew(this.account.user.id, this.form.value)
            .pipe(
                finalize(() => this.saveInProgress = false),
                takeUntil(this.destroy$),
            ).subscribe(
                {
                    next: () => {
                        this.toastr.success(this.translate.instant('TEXT_TOAST_PROFILE_SAVED'));
                    },
                    error: (e) => {
                        if (e instanceof HttpErrorResponse) {
                            this.form.setErrors({
                                server: `ERROR_API_${e.error.message}`,
                            });
                        } else {
                            alert(e);
                        }
                    },
                    complete: () => console.log('Profil güncellendi')
                })
    }
}
