import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountApi } from '../../../../api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from 'src/app/_shared/_services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-page-login',
    templateUrl: './page-login.component.html',
    styleUrls: ['./page-login.component.scss'],
})

export class PageLoginComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    loginForm!: FormGroup;
    loginInProgress = false;

    registerForm!: FormGroup;
    registerInProgress = false;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private account: AccountApi,
        private auth: AuthenticationService,
        private cart: CartService,
        private toastr: ToastrService,
        private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.createLoginForm();
        this.createRegisterForm();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    createLoginForm() {
        this.loginForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]],
            remember: [false],
        });
    }

    createRegisterForm() {
        this.registerForm = this.fb.group({
            userName: ['', Validators.required],
            userSurname: ['', Validators.required],
            userMail: ['', [Validators.required, Validators.email]],
            userCompanyName: ['', Validators.required],
            userTaxNumber: ['', Validators.required],
            userPhoneNumber: ['', [Validators.required, Validators.pattern("^[0-9]*$"),
            Validators.minLength(10), Validators.maxLength(10)]],
            password: ['', [Validators.required]]
        });
    }

    login(): void {
        this.loginForm.markAllAsTouched();

        if (this.loginInProgress || this.loginForm.invalid) {
            return;
        }

        this.loginInProgress = true;

        this.account.signInNew(
            this.loginForm.value.email,
            this.loginForm.value.password,
        ).pipe(
            finalize(() => this.loginInProgress = false),
            takeUntil(this.destroy$),
        ).subscribe(
            {
                next: (data) => {
                    this.auth.setToken(data.token);
                    this.auth.loginEvent();
                    this.cart.load();
                    this.router.navigateByUrl('/account/dashboard').then();
                },
                error: (e) => {
                    if (e instanceof HttpErrorResponse) {
                        this.loginForm.setErrors({
                            server: `ERROR_API_${e.error.message}`,
                        });
                    } else {
                        alert(e);
                    }
                },
                complete: () => {
                    this.toastr.success(this.translate.instant('TEXT_TOAST_LOGIN_WELCOME'));
                }
            })
    }

    register(): void {
        this.registerForm.markAllAsTouched();

        if (this.registerInProgress || this.registerForm.invalid) {
            return;
        }

        this.registerInProgress = true;

        this.account.signUpNew(
            this.registerForm.value
        ).pipe(
            finalize(() => this.registerInProgress = false),
            takeUntil(this.destroy$),
        ).subscribe(
            {
                next: () => {
                    this.router.navigateByUrl('/').then();
                },
                error: (e) => {
                    if (e instanceof HttpErrorResponse) {
                        this.loginForm.setErrors({
                            server: `ERROR_API_${e.error.message}`,
                        });
                    } else {
                        alert(e);
                    }
                },
                complete: () => {
                    this.toastr.success(this.translate.instant('TEXT_TOAST_REGISTER_APPROVE'));
                }
            })
    }
}