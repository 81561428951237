import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { finalize, map, switchMap, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AddressFormComponent } from '../../../shared/components/address-form/address-form.component';
import { AccountApi, EditAddressDataNew } from '../../../../api';
import { _Address } from 'src/app/_shared/_interfaces/address';

@Component({
    selector: 'app-page-edit-address',
    templateUrl: './page-edit-address.component.html',
    styleUrls: ['./page-edit-address.component.scss'],
})
export class PageEditAddressComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    form!: FormGroup;

    @ViewChild(AddressFormComponent) addressForm!: AddressFormComponent;

    addressId: number|null = null;
    saveInProgress = false;
    firstOrDefaultAddress: boolean = false;

    constructor(
        private account: AccountApi,
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
    ) { }

    ngOnInit(): void {
        this.form = this.fb.group({
            address: [],
            addressIsDefault: [false],
        });

        this.route.params.pipe(
            map(x => x['id'] ? parseFloat(x['id']) : null),
            switchMap(addressId => combineLatest([
                addressId ? this.account.getAddressNew(addressId) : of(null),
                this.account.getDefaultAddressNew(),
            ])),
            takeUntil(this.destroy$),
        ).subscribe(([address, defaultAddress]) => {

            if (address) {

                this.addressId = address.id;

                this.form.get('address')!.setValue({
                    id: address.id,
                    userId : address.userId,
                    // userName: this.account.user.userName,
                    // userSurname: this.account.user.userSurname,
                    // userCompanyName: this.account.user.userCompanyName,
                    // country: address.country,
                    addressTitle: address.addressTitle,
                    addressDescription: address.addressDescription,
                    provinceId: address.provinceId,
                    districtId: address.districtId,
                    // postcode: address.postcode,
                    // userMail: this.account.user.userMail,
                    // userPhoneNumber: this.account.user.userPhoneNumber,
                    addressType: address.addressType
                });
            }

            this.firstOrDefaultAddress = !defaultAddress || (address !== null && address.addressIsDefault);
            this.form.get('addressIsDefault')!.setValue(this.firstOrDefaultAddress);

            if (this.firstOrDefaultAddress) {
                this.form.get('addressIsDefault')!.disable();
            } else {
                this.form.get('addressIsDefault')!.enable();
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    save(): void {
        this.form.markAllAsTouched();
        this.addressForm.markAsTouched();


        if (this.saveInProgress || this.form.invalid){
            return;
        }

        const _addressData: EditAddressDataNew = {
            ...this.form.value.address,
            addressIsDefault: this.form.value.addressIsDefault || this.firstOrDefaultAddress,
        };

        this.saveInProgress = true;

        let saveMethod: Observable<_Address>;

        if (this.addressId) {
            saveMethod = this.account.editAddressNew(this.addressId, _addressData);
        } else {
            saveMethod = this.account.addAddressNew(_addressData);
        }

        saveMethod.pipe(
            finalize(() => this.saveInProgress = false),
            takeUntil(this.destroy$),
        ).subscribe(() => this.router.navigateByUrl('/account/addresses'));
    }
}
