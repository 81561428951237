import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AddNewsletterData } from "../base";

function error(message: string): HttpErrorResponse {
    return new HttpErrorResponse({
        status: 400,
        statusText: 'Bad Request',
        error: { message },
    });
}

@Injectable({
    providedIn: 'root'
})

export class ImplementationExtraApi {
    baseURL: string = environment.baseUrl + "/";

    constructor(
        private http: HttpClient) {
    }

    addNewsletterNew(data: AddNewsletterData): Observable<void> {
        return this.http.post<void>(this.baseURL + "emailsubscription", data)
    }

}