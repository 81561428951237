import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccountApi } from '../../../../api';
import { Subject } from 'rxjs';
import { Address } from '../../../../interfaces/address';
import { finalize, mergeMap, takeUntil } from 'rxjs/operators';
import { UrlService } from '../../../../services/url.service';
import { _Address } from 'src/app/_shared/_interfaces/address';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-page-addresses',
    templateUrl: './page-addresses.component.html',
    styleUrls: ['./page-addresses.component.scss'],
})
export class PageAddressesComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    addresses: Address[] = [];

   _addresses: _Address[] = [];

    removeInProgress: number[] = [];

    constructor(
        private toastr: ToastrService,
        private translate: TranslateService,
        private account: AccountApi,
        public url: UrlService,
    ) { }

    ngOnInit(): void {
        this.account.getAddressesNew().pipe(takeUntil(this.destroy$)).subscribe(x => {
            x.forEach(element =>{
                element.user = this.account.user;
            })
            this._addresses = x;
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    remove(_address: _Address): void {
        if (this.removeInProgress.indexOf(_address.id) !== -1) {
            return;
        }

        this.removeInProgress.push(_address.id);

        this.account.delAddressNew(_address.id).pipe(
            mergeMap(() => this.account.getAddressesNew()),
            finalize(() => {
                const index = this.removeInProgress.indexOf(_address.id);

                if (index !== -1) {
                    this.toastr.error(
                        this.translate.instant('TEXT_TOAST_ADDRESS_CANNOT_BE_DELETED')
                    )
                    this.removeInProgress.splice(index, 1);
                }
            }),
            takeUntil(this.destroy$),
        ).subscribe(_addresses => this._addresses = _addresses);
    }
}
