import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Product } from '../interfaces/product';
import { BehaviorSubject, Observable, of, Subject, timer } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../_shared/_services/api.service';
import { AuthenticationService } from '../_shared/_services/auth.service';
import { TranslateService } from '@ngx-translate/core';

export interface CartItem {
    product: Product;
    options: {
        name: string;
        value: string;
    }[];
    quantity: number;
}

interface CartTotal {
    title: string;
    price: number;
    type: 'shipping' | 'fee' | 'tax' | 'other';
}

interface CartData {
    cartPublicId: string;
    items: CartItem[];
    quantity: number;
    subtotal: number;
    totals: CartTotal[];
    total: number;
    userDiscountRatio: number;
    totalDiscountRatio: number;
}

@Injectable({
    providedIn: 'root',
})

export class CartService {
    private data: CartData = {
        cartPublicId: '',
        items: [],
        quantity: 0,
        subtotal: 0,
        totals: [],
        total: 0,
        userDiscountRatio: 0,
        totalDiscountRatio: 0
    };

    private itemsSubject$: BehaviorSubject<CartItem[]> = new BehaviorSubject(this.data.items);
    private quantitySubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.quantity);
    private subtotalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.subtotal);
    private totalsSubject$: BehaviorSubject<CartTotal[]> = new BehaviorSubject(this.data.totals);
    private totalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.total);
    private userDiscountRatio$: BehaviorSubject<number> = new BehaviorSubject(this.data.userDiscountRatio);
    private totalDiscountRatio$: BehaviorSubject<number> = new BehaviorSubject(this.data.totalDiscountRatio);
    private onAddingSubject$: Subject<Product> = new Subject();
    private onAddingErrorSubject$: Subject<string> = new Subject();

    get items(): ReadonlyArray<CartItem> {
        return this.data.items;
    }

    get quantity(): number {
        return this.data.quantity;
    }

    get subtotal(): number {
        return this.data.subtotal;
    }

    get totals(): ReadonlyArray<CartTotal> {
        return this.data.totals;
    }

    get total(): number {
        return this.data.total;
    }

    readonly items$: Observable<CartItem[]> = this.itemsSubject$.asObservable();
    readonly quantity$: Observable<number> = this.quantitySubject$.asObservable();
    readonly subtotal$: Observable<number> = this.subtotalSubject$.asObservable();
    readonly totals$: Observable<CartTotal[]> = this.totalsSubject$.asObservable();
    readonly total$: Observable<number> = this.totalSubject$.asObservable();
    readonly userDiscountRatio$$: Observable<number> = this.userDiscountRatio$.asObservable();
    readonly totalDiscountRatio$$: Observable<number> = this.totalDiscountRatio$.asObservable();
    readonly onAdding$: Observable<Product> = this.onAddingSubject$.asObservable();
    readonly onAddingError$: Observable<string> = this.onAddingErrorSubject$.asObservable();

    constructor(
        private api: ApiService,
        private authenticationService: AuthenticationService,
        private translate: TranslateService,
        @Inject(PLATFORM_ID) private platformId: any) {
        if (isPlatformBrowser(this.platformId)) {
            this.load();
            //this.calc();
        }
    }

    add(product: Product, quantity: number, options: { name: string; value: string }[] = []): Observable<CartItem> {
        // timer only for demo

        if (!this.authenticationService.loggedIn()) {
            this.onAddingErrorSubject$.next(this.translate.instant('TEXT_PLEASE_LOGIN_FOR_ADD_TO_CART'))

            return of(null)
        }

        let item = this.items.find(eachItem => {

            if (!this.authenticationService.loggedIn()) {
                return false;
            }

            if (eachItem.product.id !== product.id || eachItem.options.length !== options.length) {
                return false;
            }

            if (eachItem.options.length) {
                for (const option of options) {
                    if (!eachItem.options.find(itemOption => itemOption.name === option.name && itemOption.value === option.value)) {
                        return false;
                    }
                }
            }

            return true;
        });

        if (item) {
            item.quantity += quantity;
        } else {
            item = { product, quantity, options };
        }

        const cartdata = [{ productPublicId: item.product.productPublicId, productCount: item.quantity }]
        return this.api.post<CartData>("cart/update", { userPublicId: '', addedCartItems: cartdata }).pipe(
            map((data: CartData) => {
                this.data.items = data.items;
                this.data.quantity = data.quantity;
                this.data.subtotal = data.subtotal;
                this.data.total = data.total;
                this.data.userDiscountRatio = data.userDiscountRatio;
                this.data.totalDiscountRatio = data.totalDiscountRatio;
                this.itemsSubject$.next(this.data.items);
                this.quantitySubject$.next(this.data.quantity);
                this.subtotalSubject$.next(this.data.subtotal);
                this.totalSubject$.next(this.data.total);
                this.userDiscountRatio$.next(this.data.userDiscountRatio);
                this.totalDiscountRatio$.next(this.data.totalDiscountRatio);
                this.onAddingSubject$.next(product);
                return data.items.find(t => t.product == product);
            }),

            catchError((err) => {
                this.onAddingErrorSubject$.next(err.error)
                return of(null)
            }),
        )
    }

    update(updates: { item: CartItem, quantity: number }[]): Observable<void> {
        // timer only for demo
        return timer(350).pipe(map(() => {
            updates.forEach(update => {
                const item = this.items.find(eachItem => eachItem === update.item);

                if (item) {
                    item.quantity = update.quantity;
                }
            });

            this.save();
            //this.calc();
        }));
    }

    remove(item: CartItem): Observable<void> {
        const cartdata = [{ productPublicId: item.product.productPublicId, productCount: item.quantity }]

        this.api.post("cart/removeitem", { userPublicId: '', addedCartItems: cartdata }).subscribe((data: CartData) => {
            if (data.items == null) {
                this.data.items = []
            } else {
                this.data.items = data.items
            }
            this.data.quantity = data.quantity;
            this.data.subtotal = data.subtotal;
            this.data.total = data.total;
            this.data.userDiscountRatio = data.userDiscountRatio;
            this.data.totalDiscountRatio = data.totalDiscountRatio;
            this.itemsSubject$.next(this.data.items);
            this.quantitySubject$.next(this.data.quantity);
            this.subtotalSubject$.next(this.data.subtotal);
            this.totalSubject$.next(this.data.total);
            this.userDiscountRatio$.next(this.data.userDiscountRatio);
            this.totalDiscountRatio$.next(this.data.totalDiscountRatio);
        })
        return of()
    }

    private calc(): void {
        let quantity = 0;
        let subtotal = 0;

        this.data.items.forEach(item => {
            quantity += item.quantity;
            subtotal += item.product.price * item.quantity;
        });

        const totals: CartTotal[] = [];

        totals.push({
            title: 'SHIPPING',
            price: 25,
            type: 'shipping',
        });
        totals.push({
            title: 'TAX',
            price: subtotal * 0.20,
            type: 'tax',
        });

        const total = subtotal + totals.reduce((acc, eachTotal) => acc + eachTotal.price, 0);

        this.data.quantity = quantity;
        this.data.subtotal = subtotal;
        this.data.totals = totals;
        this.data.total = total;

        this.itemsSubject$.next(this.data.items);
        this.quantitySubject$.next(this.data.quantity);
        this.subtotalSubject$.next(this.data.subtotal);
        this.totalsSubject$.next(this.data.totals);
        this.totalSubject$.next(this.data.total);
    }

    private save(): void {
        // localStorage.setItem('cartItems', JSON.stringify(this.data.items));
        const cartdata = this.data.items.map((x) => {
            return { productPublicId: x.product.productPublicId, productCount: x.quantity }
        })

        this.api.post("cart/update", { userPublicId: '', addedCartItems: cartdata }).subscribe((data: CartData) => {
            this.data.items = data.items;
            this.data.quantity = data.quantity;
            this.data.subtotal = data.subtotal;
            this.data.total = data.total;
            this.data.totalDiscountRatio = data.totalDiscountRatio;
            this.data.userDiscountRatio = data.userDiscountRatio;
            this.itemsSubject$.next(this.data.items);
            this.quantitySubject$.next(this.data.quantity);
            this.subtotalSubject$.next(this.data.subtotal);
            this.totalSubject$.next(this.data.total);
            this.userDiscountRatio$.next(this.data.userDiscountRatio);
            this.totalDiscountRatio$.next(this.data.totalDiscountRatio);
        })
    }

    public load(): void {
        if (!this.authenticationService.loggedIn()) {
            return
        }

        this.api.get("cart/detail").subscribe((data: CartData) => {
            if (data.items == null) {
                this.data.items = [];
            } else {
                this.data.items = data.items;
            }
            this.data.quantity = data.quantity;
            this.data.subtotal = data.subtotal;
            this.data.total = data.total;
            this.data.userDiscountRatio = data.userDiscountRatio;
            this.data.totalDiscountRatio = data.totalDiscountRatio;
            this.itemsSubject$.next(this.data.items);
            this.quantitySubject$.next(this.data.quantity);
            this.subtotalSubject$.next(this.data.subtotal);
            this.totalSubject$.next(this.data.total);
            this.userDiscountRatio$.next(this.data.userDiscountRatio);
            this.totalDiscountRatio$.next(this.data.totalDiscountRatio);
        })
    }

    // load(): void {
    //     const items = localStorage.getItem('cartItems');
    //     if (items) {
    //         this.data.items = JSON.parse(items)
    //     }
    // }

    clearCartData() {
        this.data = {
            cartPublicId: '',
            items: [],
            quantity: 0,
            subtotal: 0,
            totals: [],
            total: 0,
            userDiscountRatio: 0,
            totalDiscountRatio: 0,
        };

        this.itemsSubject$.next(this.data.items);
        this.quantitySubject$.next(this.data.quantity);
        this.subtotalSubject$.next(this.data.subtotal);
        this.totalsSubject$.next(this.data.totals);
        this.totalSubject$.next(this.data.total);
        this.userDiscountRatio$.next(this.data.userDiscountRatio);
        this.totalDiscountRatio$.next(this.data.totalDiscountRatio);
    }

}