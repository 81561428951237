import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AccountApi, AddNewsletterData, ChangePasswordData, EditAddressData, EditAddressDataNew, EditProfileData, EditProfileDataNew, EditUserData, GetOrdersListOptions } from '../base';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/interfaces/user';
import { tap } from 'rxjs/operators';
import { Address } from '../../interfaces/address';
import { OrdersList, _OrderList } from '../../interfaces/list';
import { Order } from '../../interfaces/order';
import {
    accountChangePassword,
    accountEditProfile,
    accountSignIn,
    accountSignOut,
    accountSignUp,
    addAddress,
    delAddress,
    editAddress,
    getAddress,
    getAddresses,
    getDefaultAddress,
    getOrderById,
    getOrderByToken,
    getOrdersList,
} from '../../../fake-server/endpoints';
import { AuthenticationResult } from 'src/app/_shared/_interfaces/authenticationResult';
import { ImplementationAccountApi } from '../implementation-api/implementation-account.api';
import { ImplementationOrderApi } from '../implementation-api/implementation-order.api';
import { ImplementationExtraApi } from '../implementation-api/implementation-extra.api';
import { ImplementationAddressApi } from '../implementation-api/implemantation-address.api';
import { _User } from 'src/app/_shared/_interfaces/user';
import { _Order } from 'src/app/_shared/_interfaces/order';
import { _Address, _AddressData } from 'src/app/_shared/_interfaces/address';
import { District } from 'src/app/_shared/_interfaces/district';
import { Province } from 'src/app/_shared/_interfaces/province';
import { CartService } from 'src/app/services/cart.service';


@Injectable()
export class FakeAccountApi extends AccountApi {


    private userSubject: BehaviorSubject<User | null>;


    get user(): User | null { return this.userSubject.value; }


    readonly user$: Observable<User | null>;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private impAccountApi: ImplementationAccountApi,
        private impOrderApi: ImplementationOrderApi,
        private impExtraApi: ImplementationExtraApi,
        private impAddressApi: ImplementationAddressApi,
        private cart:CartService
        ) {
        super();

        let storedUser = null;

        if (isPlatformBrowser(this.platformId)) {
            storedUser = localStorage.getItem('user');
            storedUser = storedUser ? JSON.parse(storedUser) : null;
        }

        this.userSubject = new BehaviorSubject<User | null>(storedUser);
        this.user$ = this.userSubject.asObservable();

    }

    signIn(email: string, password: string): Observable<User> {
        return accountSignIn(email, password).pipe(
            tap(user => this.setUser(user)),
        );
    }

    signInNew(email: string, password: string): Observable<AuthenticationResult> {
        return this.impAccountApi.accountSignInNew(email, password).pipe(

            tap(data => this.setUser(data.user)),
        );
    }

    signUp(email: string, password: string): Observable<User> {
        return accountSignUp(email, password).pipe(
            tap(user => this.setUser(user)),
        );
    }

    signUpNew(data: EditUserData): Observable<_User> {
        return this.impAccountApi.accountSignUp(data);
    }

    signOut(): Observable<void> {
        return accountSignOut().pipe(

            tap(() => {
                localStorage.clear()
                this.cart.clearCartData();
                this.setUser(null)
            }),
        );
    }

    editProfile(data: EditProfileData): Observable<User> {
        return accountEditProfile(data).pipe(
            tap(user => this.setUser(user)),
        );
    }

    editProfileNew(userId: number, data: EditProfileDataNew): Observable<_User> {
        return this.impAccountApi.accountEditProfileNew(userId, data).pipe(
            tap(user => this.setUser(user)),
        );
    }

    changePassword(oldPassword: string, newPassword: string): Observable<void> {
        return accountChangePassword(oldPassword, newPassword);
    }

    changePasswordNew(data: ChangePasswordData): Observable<string> {
        return this.impAccountApi.accountChangePasswordNew(data);
    }

    addNewsletterNew(data: AddNewsletterData): Observable<void> {
        return this.impExtraApi.addNewsletterNew(data);
    }

    addAddress(data: EditAddressData): Observable<Address> {
        return addAddress(data);
    }

    addAddressNew(data: EditAddressDataNew): Observable<_Address> {
        return this.impAddressApi.addAddressNew(data);
    }

    editAddress(addressId: number, data: EditAddressData): Observable<Address> {
        return editAddress(addressId, data);
    }

    editAddressNew(addressId: number, data: EditAddressDataNew): Observable<_Address> {
        return this.impAddressApi.editAddressNew(addressId, data);
    }

    delAddress(addressId: number): Observable<void> {
        return delAddress(addressId);
    }

    delAddressNew(addressId: number): Observable<void> {
        return this.impAddressApi.delAddressNew(addressId);
    }

    getDefaultAddress(): Observable<Address> {
        return getDefaultAddress();
    }

    getDefaultAddressNew(): Observable<_Address[]> {
        return this.impAddressApi.getAddressesNew();
    }

    getAddress(addressId: number): Observable<Address> {
        return getAddress(addressId);
    }

    getAddressNew(addressId: number): Observable<_Address> {
        return this.impAddressApi.getAddressNew(addressId);
    }

    getAddresses(): Observable<Address[]> {
        return getAddresses();
    }

    getAddressesNew(): Observable<_Address[]> {
        return this.impAddressApi.getAddressesNew();
    }

    getOrdersList(options?: GetOrdersListOptions): Observable<OrdersList> {
        return getOrdersList(options);
    }

    getOrdersListNew(options?: GetOrdersListOptions): Observable<_OrderList> {
        return this.impOrderApi.getOrdersListNew(options)
    }

    getOrderById(id: number): Observable<Order> {
        return getOrderById(id);
    }

    getOrderByIdNew(id: string): Observable<_Order> {
        return this.impOrderApi.getOrderByIdNew(id)
    }

    getOrderByToken(token: string): Observable<Order> {
        return getOrderByToken(token);
    }

    getProvinces(): Observable<Province[]> {
        return this.impAddressApi.getProvinces();
     }

     getDistricts(provinceId: number): Observable<District[]> {
         return this.impAddressApi.getDistricts(provinceId);
     }

    private setUser(user: User | null): void {
        this.userSubject.next(user);

        localStorage.setItem('user', JSON.stringify(user));
    }
}
