import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment.prod';
import { PaginatedResult, Pagination } from '../_interfaces/pagination';
import { ProductFilter } from '../_interfaces/product-filter';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  baseURL = environment.baseUrl;

  constructor(
    private http: HttpClient) {
  }

  // CREATE
  post<T>(extension: string, model: T | any): Observable<T | T[]> {
    return this.http.post<T | T[]>(`${this.baseURL}/${extension}`, model);
  }

  specialPost<T>(extension: string, model: T | any): Observable<T> {
    return this.http.post<T>(`${this.baseURL}/${extension}`, model);
  }

  public async GetFilter(slug: string): Promise<any> {
    return this.http.post("product/check/" + slug, {}).toPromise()
      .then(res => { return res });
  }

  // READ
  get<T>(extension: string | any): Observable<T | T[]> {
    return this.http.get<T | T[]>(`${this.baseURL}/${extension}`);
  }

  // READ WITH PAGINATION
  getWithPagination<T>(extension: string | any, pagination: Pagination): Observable<PaginatedResult<T[]>> {
    const params = this.makeFilterHeader(pagination);
    const paginatedResult: PaginatedResult<T[]> = new PaginatedResult<T[]>();
    return this.http.get<T[]>(`${this.baseURL}/${extension}`, { observe: 'response', params }).pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') != null) {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }

  // UPDATE
  update<T>(extension: string, model: T | any): Observable<T | T[]> {
    return this.http.put<T | T[]>(`${this.baseURL}/${extension}`, model);
  }

  // DELETE
  delete<T>(extension: string): Observable<T | T[]> {
    return this.http.delete<T | T[]>(`${this.baseURL}/${extension}`);
  }

  // FILTER
  productFilter<T>(extension: string | any, data: ProductFilter,): Observable<PaginatedResult<T[]>> {
    const params = this.makeFilterHeader(data)
    const paginatedResult: PaginatedResult<T[]> = new PaginatedResult<T[]>();
    return this.http.get<T[]>(`${this.baseURL}/${extension}`, { observe: 'response', params }).pipe(
      map(response => {
        paginatedResult.result = response.body
        if (response.headers.get('Pagination') != null) {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }

  // FILTER HEADER
  makeFilterHeader(filterData: any): HttpParams {
    let params = new HttpParams();
    for (var prop in filterData) {
      if (filterData[prop] != null) {
        params = params.append(prop, filterData[prop].toString());
      }
    }
    return params;
  }

  public getPDF(extension: string): Observable<any> {
    var authorization = 'Bearer ' + localStorage.getItem("token");
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": authorization, responseType: 'blob'
    });
    return this.http.get<Blob>(`${this.baseURL}/${extension}`, { headers: headers, responseType: 'blob' as 'json' });
  }

  public getFile(extension: string, pagination: any) {
    var authorization = 'Bearer ' + localStorage.getItem("token");
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": authorization, responseType: 'blob'
    });
    const params = this.makeFilterHeader(pagination);
    return this.http.post<Blob>(`${this.baseURL}/${extension}`, params, { headers: headers, responseType: 'arraybuffer' as 'json' });
  }

}
