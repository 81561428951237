<app-block-space layout="after-header"></app-block-space>

<div class="block">
    <div class="container container--max--lg">
        <div class="row">
            <div class="col-md-6 d-flex">
                <div class="card flex-grow-1 mb-md-0 mr-0 mr-lg-3 ml-0 ml-lg-4">
                    <div class="card-body card-body--padding--2">
                        <h3 class="card-title">{{ 'HEADER_LOGIN'|translate }}</h3>
                        <form [formGroup]="loginForm">
                            <div *ngIf="loginForm.errors && loginForm.errors['server']"
                                class="alert alert-sm alert-danger">
                                {{ ((loginForm.errors && loginForm.errors['server']) || '')|translate }}
                            </div>
                            <div class="form-group">
                                <label for="signin-email">{{ 'INPUT_EMAIL_ADDRESS_LABEL'|translate }}</label>
                                <input id="signin-email" type="email" class="form-control"
                                    [class.is-invalid]="loginForm.controls['email']|isInvalid"
                                    placeholder="ad@ornek.com" formControlName="email">
                                <div class="invalid-feedback">
                                    <div *ngIf="loginForm.controls['email']|hasError:'required'">
                                        {{ 'ERROR_FORM_REQUIRED'|translate }}
                                    </div>
                                    <div *ngIf="loginForm.controls['email']|hasError:'email'">
                                        {{ 'ERROR_FORM_INCORRECT_EMAIL'|translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="signin-password">{{ 'INPUT_PASSWORD_LABEL'|translate }}</label>
                                <input id="signin-password" type="password" class="form-control"
                                    [class.is-invalid]="loginForm.controls['password']|isInvalid"
                                    [placeholder]="'INPUT_PASSWORD_PLACEHOLDER'|translate" formControlName="password">
                                <div class="invalid-feedback">
                                    <div *ngIf="loginForm.controls['password']|hasError:'required'">
                                        {{ 'ERROR_FORM_REQUIRED'|translate }}
                                    </div>
                                </div>
                                <small class="form-text text-muted">
                                    <a routerLink="/account/forgot-password">{{ 'LINK_FORGOT_PASSWORD'|translate }}</a>
                                </small>
                            </div>
                            <div class="form-group">
                                <div class="form-check">
                                    <app-checkbox class="form-check-input" formControlName="remember"
                                        #cbSignInRemember="appCheckbox"></app-checkbox>
                                    <label class="form-check-label" [htmlFor]="cbSignInRemember.inputId">
                                        {{ 'INPUT_REMEMBER_ME_LABEL'|translate }}
                                    </label>
                                </div>
                            </div>
                            <div class="form-group mb-0">
                                <button type="submit" class="btn btn-primary mt-3" [class.btn-loading]="loginInProgress"
                                    (click)="login()">
                                    {{ 'BUTTON_LOGIN'|translate }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-6 d-flex mt-4 mt-md-0">
                <div class="card flex-grow-1 mb-0 ml-0 ml-lg-3 mr-0 mr-lg-4">
                    <div class="card-body card-body--padding--2">
                        <h3 class="card-title">{{ 'HEADER_REGISTER'|translate }}</h3>
                        <form [formGroup]="registerForm">
                            <div *ngIf="registerForm.errors && registerForm.errors['server']"
                                class="alert alert-sm alert-danger">
                                {{ ((registerForm.errors && registerForm.errors['server']) || '')|translate }}
                            </div>
                            <div class="form-group">
                                <label for="signup-userName">{{ 'INPUT_FIRST_NAME_LABEL'|translate }}</label>
                                <input id="signup-userName" type="text" class="form-control"
                                    [class.is-invalid]="registerForm.controls['userName']|isInvalid"
                                    [placeholder]="'INPUT_FIRST_NAME_PLACEHOLDER'|translate" formControlName="userName">
                                <div class="invalid-feedback">
                                    <div *ngIf="registerForm.controls['userName']|hasError:'required'">
                                        {{ 'ERROR_FORM_REQUIRED'|translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="signup-userSurname">{{ 'INPUT_LAST_NAME_LABEL'|translate }}</label>
                                <input id="signup-userSurname" type="text" class="form-control"
                                    [class.is-invalid]="registerForm.controls['userSurname']|isInvalid"
                                    [placeholder]="'INPUT_LAST_NAME_PLACEHOLDER'|translate"
                                    formControlName="userSurname">
                                <div class="invalid-feedback">
                                    <div *ngIf="registerForm.controls['userSurname']|hasError:'required'">
                                        {{ 'ERROR_FORM_REQUIRED'|translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="signup-userMail">{{ 'INPUT_EMAIL_ADDRESS_LABEL'|translate }}</label>
                                <input id="signup-userMail" type="email" class="form-control"
                                    [class.is-invalid]="registerForm.controls['userMail']|isInvalid"
                                    [placeholder]="'INPUT_EMAIL_ADDRESS_PLACEHOLDER'|translate"
                                    formControlName="userMail">
                                <div class="invalid-feedback">
                                    <div *ngIf="registerForm.controls['userMail']|hasError:'required'">
                                        {{ 'ERROR_FORM_REQUIRED'|translate }}
                                    </div>
                                    <div *ngIf="registerForm.controls['userMail']|hasError:'email'">
                                        {{ 'ERROR_FORM_INCORRECT_EMAIL'|translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="signup-userCompanyName">{{ 'INPUT_COMPANY_LABEL'|translate }}</label>
                                <input id="signup-userCompanyName" type="text" class="form-control"
                                    [class.is-invalid]="registerForm.controls['userCompanyName']|isInvalid"
                                    [placeholder]="'INPUT_COMPANY_PLACEHOLDER'|translate"
                                    formControlName="userCompanyName">
                                <div class="invalid-feedback">
                                    <div *ngIf="registerForm.controls['userCompanyName']|hasError:'required'">
                                        {{ 'ERROR_FORM_REQUIRED'|translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="signup-userTaxNumber">{{ 'INPUT_VIN_LABEL'|translate }}</label>
                                <input id="signup-userTaxNumber" type="text" class="form-control"
                                    [class.is-invalid]="registerForm.controls['userTaxNumber']|isInvalid"
                                    [placeholder]="'INPUT_VIN_PLACEHOLDER'|translate" formControlName="userTaxNumber">
                                <div class="invalid-feedback">
                                    <div *ngIf="registerForm.controls['userTaxNumber']|hasError:'required'">
                                        {{ 'ERROR_FORM_REQUIRED'|translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="signup-userPhoneNumber">{{ 'INPUT_PHONE_NUMBER_LABEL'|translate }}</label>
                                <input id="signup-userPhoneNumber" type="text" class="form-control"
                                    [class.is-invalid]="registerForm.controls['userPhoneNumber']|isInvalid"
                                    [placeholder]="'INPUT_PHONE_NUMBER_PLACEHOLDER'|translate"
                                    formControlName="userPhoneNumber">
                                <div class="invalid-feedback">
                                    <div *ngIf="registerForm.controls['userPhoneNumber']|hasError:'required'">
                                        {{ 'ERROR_FORM_REQUIRED'|translate }}
                                    </div>
                                    <div *ngIf="(registerForm.controls['userPhoneNumber']|hasError:'pattern') || (registerForm.controls['userPhoneNumber']|hasError:'minlength') || (registerForm.controls['userPhoneNumber']|hasError:'maxlength')">
                                        {{ 'ERROR_FORM_INCORRECT_PHONE'|translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="signup-password">{{ 'INPUT_PASSWORD_LABEL'|translate }}</label>
                                <input id="signup-password" type="password" class="form-control"
                                    [class.is-invalid]="registerForm.controls['password']|isInvalid"
                                    [placeholder]="'INPUT_PASSWORD_PLACEHOLDER'|translate" formControlName="password">
                                <div class="invalid-feedback">
                                    <div *ngIf="registerForm.controls['password']|hasError:'required'">
                                        {{ 'ERROR_FORM_REQUIRED'|translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-0">
                                <button type="submit" class="btn btn-primary mt-3"
                                    [class.btn-loading]="registerInProgress" (click)="register()">
                                    {{ 'BUTTON_REGISTER'|translate }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-block-space layout="before-footer"></app-block-space>