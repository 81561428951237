import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { Country } from '../../../../interfaces/country';
import { AccountApi, CountriesApi } from '../../../../api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Province } from 'src/app/_shared/_interfaces/province';
import { District } from 'src/app/_shared/_interfaces/district';

let uniqueId = 0;

export interface AddressFormValue {
    id: number;
    userId: number;
    addressTitle: string;
    addressDescription: string;
    provinceName: string;
    districtName: string;
    addressType: string;
}

@Component({
    selector: 'app-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AddressFormComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AddressFormComponent),
            multi: true,
        },
    ],
})
export class AddressFormComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
    private destroy$: Subject<void> = new Subject<void>();
    private readonly dataId: number = ++uniqueId;

    form!: FormGroup;

    countries: Country[] = [];
    provinces: Province[] = [];
    districts: District[] = [];

    get formId(): string {
        return `app-address-form-id-${this.dataId}`;
    }

    changeFn: (_: AddressFormValue) => void = () => { };

    touchedFn: () => void = () => { };

    constructor(
        private fb: FormBuilder,
        private countriesService: CountriesApi,
        private account: AccountApi
    ) { }

    ngOnInit(): void {
        this.form = this.fb.group({
            id: [''],
            userId: ['', Validators.required],
            addressTitle: ['', Validators.required],
            addressDescription: ['', Validators.required],
            provinceId: ['', Validators.required],
            districtId: ['', Validators.required],
            addressType: ['', Validators.required]
        });

        this.form.valueChanges.subscribe(value => {
            this.changeFn(value);
            this.touchedFn();

        });

        this.countriesService.getCountries().pipe(takeUntil(this.destroy$)).subscribe(x => this.countries = x);
        this.account.getProvinces().pipe(takeUntil(this.destroy$)).subscribe(x => this.provinces = x);
    }

    provinceOnChange(event: number) {
        if (event) {
            this.account.getDistricts(event).pipe(takeUntil(this.destroy$)).subscribe(x => this.districts = x);
        } else {
            this.districts = [];
            this.form.get('districtId').setValue('');
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    registerOnChange(fn: any): void {
        this.changeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.touchedFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable({ emitEvent: false });
        } else {
            this.form.enable({ emitEvent: false });
        }
    }

    writeValue(value: any): void {

        if (typeof value !== 'object') {
            value = {};
        }

        this.form.setValue(
            {
                id: '',
                userId: this.account.user.id,
                addressTitle: '',
                addressDescription: '',
                provinceId: '',
                districtId: '',
                addressType: '',
                ...value,
            },
            { emitEvent: false },
        );

        if (this.form.controls["provinceId"].value) {
            this.account.getDistricts(this.form.controls["provinceId"].value).pipe(takeUntil(this.destroy$)).subscribe(x => {
                this.districts = x;
            });
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if(!this.form.value.id){
            delete this.form.value.id;

        }
        return this.form.valid ? null : { addressForm: this.form.errors };
    }

    markAsTouched(): void {
        this.form.markAllAsTouched();
    }
}
