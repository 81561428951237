<div class="card__loader"></div>
<div *ngIf="label" class="address-card__badge tag-badge tag-badge--theme">{{ label }}</div>
<div class="address-card__body">
    <div class="address-card__name">{{ _address?.addressTitle }}</div>
    <div class="address-card__row">
        <!-- {{ 'COUNTRY_NAME_' + address.country|translate }}<br> -->
        {{ _address?.addressDescription }}
    </div>
    <div class="address-card__row">
        <div class="address-card__row-title">{{ 'TEXT_PHONE_NUMBER'|translate }}</div>
        <div class="address-card__row-content">{{ _address?.districtName }} / {{ _address?.provinceName }}</div>
    </div>
    <div class="address-card__row">
        <div class="address-card__row-title">{{ 'TEXT_EMAIL_ADDRESS'|translate }}</div>
        <div class="address-card__row-content text-capitalize">{{ _address?.addressType }}</div>
    </div>
    <div class="address-card__footer" *ngIf="footer">
        <ng-container *ngTemplateOutlet="footer"></ng-container>
    </div>
</div>
