import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ShopCategory } from '../../interfaces/category';
import { Product } from '../../interfaces/product';
import { Brand } from '../../interfaces/brand';
import { FilterableList, ProductsList, ReviewsList } from '../../interfaces/list';
import { Review } from '../../interfaces/review';
import { Order } from '../../interfaces/order';
import {
    AddProductReviewData,
    CheckoutData,
    GetBrandsOptions,
    GetCategoriesOptions,
    GetCategoryBySlugOptions,
    GetProductReviewsOptions,
    GetProductsListOptions,
    GetSearchSuggestionsOptions,
    GetSearchSuggestionsResult,
    ShopApi,
    _CheckoutData,
} from '../base';
import {
    addProductReview,
    checkout,
    getCategories,
    getCategoryBySlug,
    getFeaturedProducts,
    getLatestProducts,
    getPopularProducts,
    getProductAnalogs,
    getProductBySlug,
    getProductReviews,
    getProductsListNew,
    getRelatedProducts,
    getSearchSuggestions,
    getSpecialOffers,
    getTopRatedProducts,
} from '../../../fake-server/endpoints';
// import { getBrands } from '../../../fake-server/endpoints/brands';
import { ImplementationShopApi } from '../implementation-api/implementation-shop.api';
import { Filter } from 'src/app/interfaces/filter';
import { _Order } from 'src/app/_shared/_interfaces/order';


@Injectable()
export class FakeShopApi extends ShopApi {



    constructor(
        private impShopApi:ImplementationShopApi) {
        super();
    }

    getCategoryBySlug(slug: string, options?: GetCategoryBySlugOptions): Observable<ShopCategory> {
        return this.impShopApi.getCategoryBySlug(slug, options);
    }

    getCategories(options?: GetCategoriesOptions): Observable<ShopCategory[]> {
        return getCategories(options);
    }

    getCategoriesNew(options?: GetCategoriesOptions): Observable<ShopCategory[]> {
        return this.impShopApi.getCategoriesNew(options);
    }

    getBrands(options?: GetBrandsOptions): Observable<Brand[]> {
        return this.impShopApi.getBrandsNew(options);
    }


    getBrandsNew(options?: GetBrandsOptions): Observable<Brand[]> {
        return this.impShopApi.getBrandsNew(options);
    }

    getProductsList(options?: GetProductsListOptions): Observable<ProductsList> {
        return this.impShopApi.getProductsListNew(options);
    }

    getProductsListNew(options?: GetProductsListOptions): Observable<ProductsList> {
        return this.impShopApi.getProductsListNew(options);
    }

    getProductBySlug(slug: string): Observable<Product> {
        return getProductBySlug(slug);
    }

    getProductBySlugNew(slug: string): Observable<Product> {
        return this.impShopApi.getProductBySlug(slug);
    }

    getProductReviews(productId: number, options?: GetProductReviewsOptions): Observable<ReviewsList> {
        return getProductReviews(productId, options);
    }

    addProductReview(productId: number, data: AddProductReviewData): Observable<Review> {
        return addProductReview(productId, data);
    }

    getProductAnalogs(productId: number): Observable<Product[]> {
        return getProductAnalogs(productId);
    }

    getRelatedProducts(productId: number, limit: number): Observable<Product[]> {
        return getRelatedProducts(productId, limit);
    }

    getRelatedProductsNew(modelId: number, limit: number): Observable<ProductsList> {
        return this.impShopApi.getRelatedProductsNew(modelId,limit)
    }

    getFeaturedProducts(categorySlug: string, limit: number): Observable<Product[]> {
        return getFeaturedProducts(categorySlug, limit);
    }

    getFeaturedProductsNew(productId: number, limit: number,orderby?:string): Observable<Product[]> {
        return this.impShopApi.getFeaturedProductsNew(productId, limit,orderby);
    }

    getPopularProducts(categorySlug: string, limit: number): Observable<Product[]> {
        return getPopularProducts(categorySlug, limit);
    }

    getTopRatedProducts(categorySlug: string, limit: number): Observable<Product[]> {
        return getTopRatedProducts(categorySlug, limit);
    }

    getSpecialOffers(limit: number): Observable<Product[]> {
        return getSpecialOffers(limit);
    }

    getLatestProducts(limit: number): Observable<Product[]> {
        return getLatestProducts(limit);
    }

    getLatestProductsNew(limit: number): Observable<Product[]> {
        return this.impShopApi.getLatestProductsNew(limit);
    }

    getSearchSuggestions(query: string, options?: GetSearchSuggestionsOptions): Observable<GetSearchSuggestionsResult> {
        return this.impShopApi.getSearchSuggestions(query,options)
    }

    checkout(data: CheckoutData): Observable<Order> {
        return checkout(data);
    }

    checkoutNew(data: _CheckoutData): Observable<_Order> {
       return this.impShopApi.checkOutNew(data)
    }


    getFilter(): Observable<Filter[]> {
        return this.impShopApi.getFilter()
     }
}
