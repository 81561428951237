import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { mustMatchValidator } from '../../../../functions/validators/must-match';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AccountApi } from '../../../../api';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/_shared/_services/auth.service';

@Component({
    selector: 'app-page-password',
    templateUrl: './page-password.component.html',
    styleUrls: ['./page-password.component.scss'],
})
export class PagePasswordComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    form!: FormGroup;

    saveInProgress = false;

    constructor(
        private account: AccountApi,
        private toastr: ToastrService,
        private translate: TranslateService,
        private fb: FormBuilder,
        private auth: AuthenticationService
    ) { }

    ngOnInit(): void {
        this.createChangePassword();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    createChangePassword() {
        this.form = this.fb.group({
            userPublicId: [this.auth.getDecodedToken()['pid'], [Validators.required]],
            oldPassword: ['', [Validators.required]],
            newPassword: ['', [Validators.required]],
            newPasswordConfirm: ['', [Validators.required]],
        }, { validators: [mustMatchValidator('newPassword', 'newPasswordConfirm')] });
    }

    save(): void {
        this.form.markAllAsTouched();

        if (this.saveInProgress || this.form.invalid) {
            return;
        }

        this.saveInProgress = true;

        this.account.changePasswordNew(this.form.value)
            .pipe(
                finalize(() => this.saveInProgress = false),
                takeUntil(this.destroy$),
            ).subscribe(
                {
                    next: () => {
                        this.toastr.success(this.translate.instant('TEXT_TOAST_PASSWORD_CHANGED'));
                    },
                    error: (e) => {
                        if (e instanceof HttpErrorResponse) {
                            this.form.setErrors({
                                server: `ERROR_API_${e.error.message}`,
                            });
                        } else {
                            alert(e);
                        }
                    },
                    complete: () => console.log('Şifre değişti')
                })
    }
}
