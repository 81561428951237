export const theme = {
    name: 'RedParts',
    url: 'https://themeforest.net/item/redparts-auto-parts-angular-template/27087440',
    author: {
        name: 'Miraz Teknoloji',
        profile_url: 'https://miraz.com.tr',
    },
    contacts: {
        address: ['25 Aralık Mah. Sokak: 6 No: 7/A'],
        email: ['info@ozyildizmotor.com'],
        phone: ['+90 (342) 251-17-00'],
        hours: ['Pzt-Cmt: 08:00pm - 18:00pm', 'Paz: -'],
        comment: ['Merhaba, işbirliğine ve önerilere her zaman açığız, iletişim bilgilerinden biriyle bizimle iletişime geçin'],
        pin: ['https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3183.910910135471!2d37.39344345145387!3d37.05960616065415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1531e6ba517ab6e7%3A0x40e7fd53b37dc5c0!2zw5Z6ecSxbGTEsXogTW90b3I!5e0!3m2!1str!2str!4v1653658755705!5m2!1str!2str']
    },
    extra:{
        terms_update: ['27.05.2022'],
        copyright_year: ['2022 - ' + (new Date()).getFullYear()]
    },
    social:{
        facebook_url: ['https://miraz.com.tr'],
        twitter_url: ['https://miraz.com.tr'],
        youtube_url: ['https://miraz.com.tr'],
        instagram_url: ['https://miraz.com.tr'],
        rss_url: ['https://miraz.com.tr']
    },
    styles:{
        footer_widget: ['one'] // Choose 'one' or 'four'
    }
};