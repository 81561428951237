import { isPlatformBrowser } from '@angular/common';
import {
    AfterContentInit,
    Directive,
    ElementRef,
    HostListener,
    Inject,
    Input,
    OnInit,
    PLATFORM_ID,
    Renderer2,
} from '@angular/core';

@Directive({
    selector: 'img[imageLoader]',
})
export class ImageLoaderDirective implements OnInit, AfterContentInit {
    @Input()
    public src!: string;
    @Input()
    public loaderSrc: string = '/assets/images/loading.gif';
    @Input()
    public errorSrc: string = '/assets/images/products/no-product-image.jpg';
    @Input()
    public lazyLoad: boolean = true;

    private alreadyTriedLoading: boolean = false;
    private alreadyTriedError: boolean = false;

    constructor(
        private el: ElementRef<HTMLImageElement>,
        private renderer: Renderer2,
        @Inject(PLATFORM_ID) private platformId: Object
    ) { }

    ngOnInit(): void {
        this.renderer.setAttribute(this.el.nativeElement, 'src', this.src);

        if (this.lazyLoad) {
            this.renderer.setAttribute(this.el.nativeElement, 'loading', 'lazy');
        }
    }

    ngAfterContentInit(): void {
        if (this.shouldDisplayLoader()) {
            this.renderer.setAttribute(this.el.nativeElement, 'src', this.loaderSrc);
        } else {
            this.renderer.setAttribute(this.el.nativeElement, 'src', this.src);
        }
    }

    @HostListener('load')
    public onLoad(): void {
        if (!this.alreadyTriedLoading) {
            this.renderer.setAttribute(this.el.nativeElement, 'src', this.src);
        }
        this.alreadyTriedLoading = true;
    }

    @HostListener('error')
    public onError(): void {
        if (!this.alreadyTriedError) {
            this.renderer.setAttribute(this.el.nativeElement, 'src', this.errorSrc);
        }
        this.alreadyTriedError = true;
    }

    private shouldDisplayLoader(): boolean {
        return (
            isPlatformBrowser(this.platformId) && !this.el.nativeElement.complete
        );
    }
}