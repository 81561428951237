// noinspection JSUnusedGlobalSymbols
export const environment = {
    production: true,
    paypalClientId: 'AfqNKvCkZ4ExY1NKEB0kwrnqpcX7JfZRC1eNr7787k8WizTyOYIwWYCxLrYry28OlLx6v41bDH1NCtA7',
    //baseUrl: document.getElementsByTagName('base')[0].href + "api/",
    //sourceUrl: document.getElementsByTagName('base')[0].href
    baseUrl: "https://csrmoto.com.tr/api",
    sourceUrl: "https://csrmoto.com.tr/",
    // baseUrl: "https://localhost:44393/api",
    // sourceUrl: "https://localhost:44393"
};