<div class="card">
    <div class="card-header">
        <h5>{{ 'HEADER_CHANGE_PASSWORD'|translate }}</h5>
    </div>
    <div class="card-divider"></div>
    <div class="card-body card-body--padding--2">
        <div class="row no-gutters">
            <div class="col-12 col-lg-7 col-xl-6" [formGroup]="form">
                <div *ngIf="form.errors && form.errors['server']" class="alert alert-sm alert-danger">
                    {{ ((form.errors && form.errors['server']) || '')|translate }}
                </div>
                <div class="form-group">
                    <label for="password-oldPassword">{{ 'INPUT_PASSWORD_CURRENT_LABEL'|translate }}</label>
                    <input
                        type="password"
                        id="password-oldPassword"
                        formControlName="oldPassword"
                        class="form-control"
                        [class.is-invalid]="form.controls['oldPassword']|isInvalid"
                        [placeholder]="'INPUT_PASSWORD_CURRENT_PLACEHOLDER'|translate"
                    >
                    <div class="invalid-feedback">
                        <div *ngIf="form.controls['oldPassword']|hasError:'required'">
                            {{ 'ERROR_FORM_REQUIRED'|translate }}
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password-newPassword">{{ 'INPUT_PASSWORD_NEW_LABEL'|translate }}</label>
                    <input
                        type="password"
                        id="password-newPassword"
                        formControlName="newPassword"
                        class="form-control"
                        [class.is-invalid]="form.controls['newPassword']|isInvalid"
                        [placeholder]="'INPUT_PASSWORD_NEW_PLACEHOLDER'|translate"
                    >
                    <div class="invalid-feedback">
                        <div *ngIf="form.controls['newPassword']|hasError:'required'">
                            {{ 'ERROR_FORM_REQUIRED'|translate }}
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password-newPasswordConfirm">{{ 'INPUT_PASSWORD_REPEAT_LABEL'|translate }}</label>
                    <input
                        type="password"
                        id="password-newPasswordConfirm"
                        formControlName="newPasswordConfirm"
                        class="form-control"
                        [class.is-invalid]="form.controls['newPasswordConfirm']|isInvalid"
                        [placeholder]="'INPUT_PASSWORD_REPEAT_PLACEHOLDER'|translate"
                    >
                    <div class="invalid-feedback">
                        <div *ngIf="form.controls['newPasswordConfirm']|hasError:'required'">
                            {{ 'ERROR_FORM_REQUIRED'|translate }}
                        </div>
                        <div *ngIf="form.controls['newPasswordConfirm']|hasError:'mustMatch'">
                            {{ 'ERROR_FORM_PASSWORD_DOES_NOT_MATCH'|translate }}
                        </div>
                    </div>
                </div>

                <div class="form-group mb-0">
                    <button
                        class="btn btn-primary mt-3"
                        [class.btn-loading]="saveInProgress"
                        (click)="save()"
                    >{{ 'BUTTON_CHANGE'|translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>
