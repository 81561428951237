import { HttpClient, HttpXhrBackend } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, timer } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class ImplementationVehicleApi {

    baseURL: string = environment.baseUrl;
    result: string[] = []
    constructor(private http: HttpClient) {
        this.test()
    }

    getMakesNew(year: number): Observable<string[]> {
        //  return timer(750).pipe(map(() => ['test']));
        //
        return timer(750).pipe(map(() => this.result));
        // return this.http.get<string[]>(this.baseURL +"brand")
    }

    getBrandsNew(): Observable<string[]> {
        const httpClient = new HttpClient(new HttpXhrBackend({
            build: () => new XMLHttpRequest()
        }));
        const baseURL = environment.baseUrl + "/";
        return httpClient.get<string[]>(baseURL + "brand")
    }

    getModelsNew(brandName: string): Observable<string[]> {

        const httpClient = new HttpClient(new HttpXhrBackend({
            build: () => new XMLHttpRequest()
        }));
        const baseURL = environment.baseUrl + "/";
        return httpClient.get<string[]>(baseURL + "model/finder/" + brandName)

    }

    test() {
        this.http.get<string[]>(this.baseURL + "brand").subscribe((data) => {
            this.result = data

        })
    }
}