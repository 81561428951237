<app-block-space layout="spaceship-ledge-height"></app-block-space>

<div class="block">
    <div class="container">
        <div class="document">
            <div class="document__header">
                <h1 class="document__title">{{ 'PAGE.POLICY.TEXT_PRIVACY_POLICY' |translate }}</h1>
                <div class="document__subtitle">{{ 'PAGE.POLICY.TEXT_UPDATED_THIS_POLICY' |translate }} {{ theme.extra.terms_update[0]}}</div> 
            </div>
            <div class="document__content card">
                <div class="typography">
                    <app-privacy-policy></app-privacy-policy>

                    <div class="document__signature">
                        <img src="assets/images/signature.jpg" width="160" height="55" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-block-space layout="before-footer"></app-block-space>
